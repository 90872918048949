import { css } from "@emotion/core";

import { colors, colorsObject } from "@ag/design-system/tokens";

import { fontStyles } from "./font-styles";
import { intercomStyles } from "./lib/intercom-styles";
import { reactModalStyles } from "./lib/react-modal-styles";
import { reactTooltipStyles } from "./lib/react-tooltip-styles";

export const globalStyles = css`
  ${fontStyles};

  html {
    height: -webkit-fill-available;
  }

  * {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
  }

  a {
    color: ${colorsObject.accent[200]};

    &:hover {
      color: ${colorsObject.accent[100]};
      text-decoration: underline;
    }
  }

  body {
    font-family: "Overpass", sans-serif;
    width: 100%;
    min-height: -webkit-fill-available;
    color: ${colorsObject.grey[900]};

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  button {
    background: none;
    border: none;

    &:focus {
      outline: none;
    }

    /* Keyboard only outline */
    &:focus-visible {
      outline: 2px solid ${colors.data.orange[500]};
    }
  }

  iframe {
    border-width: 0;
  }

  ${reactModalStyles}
  ${reactTooltipStyles}
  ${intercomStyles}
`;
