import axios from "axios";

import { CommonResponse } from "@ag/utils/types";

import { type CurrentUser, CurrentUserSchema } from "../entities/current-user";

export const getCurrentUser = async () => {
  const response = await axios.get<CommonResponse<CurrentUser>>(
    "/api/v1/users/me",
    {
      schema: CurrentUserSchema,
      apiSource: "node-auth",
    },
  );

  return response.data.success!.data;
};
