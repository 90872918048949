import { createContext } from "react";

type TAppHistoryContext = {
  historyStack: string[];

  currentPage: string | undefined;
  previousPage: string | undefined;

  addPage: (page: string) => void;
  removeFirstPage: () => void;
  removeLastPage: () => void;
  updateLastPage: (page: string) => void;
  resetStack: () => void;
};

export const AppHistoryContext = createContext<TAppHistoryContext>({
  historyStack: [],

  currentPage: undefined,
  previousPage: undefined,

  addPage: () => {},
  removeFirstPage: () => {},
  removeLastPage: () => {},
  updateLastPage: () => {},
  resetStack: () => {},
});
