export enum ContractStatus {
  Sent = "sent",
  Signed = "signed",
  Withdrawn = "withdrawn",
  Terminated = "terminated",
  Expired = "expired",
  Voided = "voided",
}

export enum ContractType {
  Fixed = "fixed",
  Vesting = "vesting",
  V2022 = "v2022",
  NovationV2022 = "novation-v2022",
}

export enum CreditingPeriod {
  FiveYears = 5,
  TenYears = 10,
}

export enum LockInPeriod {
  YearToYear = "year_to_year",
}

export enum Payout {
  YearToYear_100Percent = "year_to_year_100_percent",
  Vesting_5Years_10_15_20_25_30 = "vesting_5_years__10_15_20_25_30",
}

export enum Sanction {
  RepaymentOfUpToThreeYears = "repayment_of_up_to_three_years",
  WeRetainFuturePayoutsForAllYears = "we_retain_future_payouts_for_all_years",
}

export enum ContractedStage {
  FIELD_DEFINITION = "fieldDefinition",
  AWAITING_VALIDATION = "awaitingValidation",
  AWAITING_CONTRACT = "awaitingContract",
  SIGN_CONTRACT = "signContract",
  CONTRACTED = "contracted",
  EXPIRED = "expired",
}
