import { z } from "zod";

import { GeoJSONGeometrySchema, LatLngSchema } from "@ag/map/types";

import { ContractType } from "~features/contracts";
import { BaseFieldDefinitionSchema } from "~features/field-definition";

import { FieldActualsSchema } from "../field-actuals/schemas";
import { CarbonHarvestYearsStatsSchema } from "../stats/schemas";
import { FieldValidationStatus } from "./types";

export const FieldSchema = z.object({
  id: z.string(),
  name: z.string(),
  position: LatLngSchema,
  sizeHa: z.number(),
  harvestYear: z.number(),
  carbonUserId: z.string(),
  carbonFarmId: z.string(),
  carbonFieldDefinition: BaseFieldDefinitionSchema,
  carbonFieldActual: FieldActualsSchema.optional().nullable(),
  createdAt: z.string(),
  updatedAt: z.string(),
  activeCarbonContractId: z.string().nullable(),
  carbonFarm: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .optional(),
  completionRate: z.number(),
  contractType: z.nativeEnum(ContractType).nullable(),
  dummy: z.boolean(),
  validationStatus: z.nativeEnum(FieldValidationStatus),
  coordinates: z
    .array(z.object({ lat: z.number(), lng: z.number() }))
    .optional(),
  boundaries: GeoJSONGeometrySchema.nullable().optional(),
  potential: z
    .object({
      agronomic: z.number().nullable(),
    })
    .optional(),
});

export const FarmFieldSchema = FieldSchema.extend({
  statistics: CarbonHarvestYearsStatsSchema,
});
