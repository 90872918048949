import { QueryCache, QueryClient } from "react-query";

import { ToastNotification } from "@ag/utils/services";

type QueryOptions = {
  onError?: (error?: unknown) => void;
};

export const queryCache = new QueryCache({
  onError: (error, query) => {
    // Don't display error notification if custom onError is passed to the query (custom error message handling)
    if (!(query.options as QueryOptions).onError) {
      ToastNotification.error(error);
    }
  },
});

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      notifyOnChangeProps: "tracked",
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
  queryCache,
});
