import TranslatorUsageWarning from "./modals/TranslatorUsageWarning";
import AddFieldsUnavailable from "./modals/field/AddFieldsUnavailable";
import EditField, { EditFieldExtras } from "./modals/field/EditField";
import FieldMap, { FieldMapExtras } from "./modals/field/FieldMap";
import ResaleContractSentSuccess from "./modals/resale-contract/resale-contract-sent-success";
import AddMoreFieldsToValidationRequest, {
  AddMoreFieldsToValidationRequestExtras,
} from "./modals/validation-flow/AddMoreFieldsToValidationRequest";
import CreateValidationRequestForAnotherYear, {
  CreateValidationRequestForAnotherYearExtras,
} from "./modals/validation-flow/CreateValidationRequestForAnotherYear";
import { GlobalModalName, useGlobalModalStore } from "./store";

export type ModalContentProps = {
  onClose: () => void;
  onSuccess?: (() => void) | null;
  extras:
    | AddMoreFieldsToValidationRequestExtras
    | CreateValidationRequestForAnotherYearExtras
    | EditFieldExtras
    | FieldMapExtras
    | null;
  isOpen: boolean;
};

const GlobalModal = () => {
  const {
    name: activeModalName,
    config,
    extras,
    closeModal,
    onSuccess,
    onClose,
  } = useGlobalModalStore();

  const modals: Record<
    GlobalModalName,
    (props: ModalContentProps) => JSX.Element | null
  > = {
    [GlobalModalName.AddMoreFieldsToValidationRequest]:
      AddMoreFieldsToValidationRequest,
    [GlobalModalName.AddFieldsUnavailable]: AddFieldsUnavailable,
    [GlobalModalName.CreateValidationRequestForAnotherYear]:
      CreateValidationRequestForAnotherYear,
    [GlobalModalName.ResaleContractSentSuccess]: ResaleContractSentSuccess,
    [GlobalModalName.EditField]: EditField,
    [GlobalModalName.FieldMap]: FieldMap,
    [GlobalModalName.TranslatorUsageWarning]: TranslatorUsageWarning,
  };

  const ModalContentComponent = activeModalName
    ? modals[activeModalName]
    : null;

  const handleModalClose = () => {
    closeModal();

    if (onClose) {
      onClose();
    }
  };

  return ModalContentComponent ? (
    <ModalContentComponent
      extras={extras}
      onSuccess={onSuccess}
      onClose={handleModalClose}
      isOpen={Boolean(activeModalName)}
      {...config}
    />
  ) : null;
};

export default GlobalModal;
