import { Button } from "@ag/design-system/atoms";
import { Modal } from "@ag/design-system/organisms";
import I18n from "@ag/i18n";

import { ModalContentProps } from "~components/GlobalModal";

const ResaleContractSentSuccess = ({ onClose, isOpen }: ModalContentProps) => (
  <Modal.Root isOpen={isOpen} onRequestClose={onClose}>
    <Modal.Title>
      {I18n.t(
        "js.farmer.carbon.contracts.resale_contract_preparing_modal.title",
      )}
    </Modal.Title>

    <Modal.Content>
      {I18n.t(
        "js.farmer.carbon.contracts.resale_contract_preparing_modal.description",
      )}
    </Modal.Content>

    <Modal.Footer
      render={onClose => (
        <Button onClick={onClose}>
          {I18n.t(
            "js.farmer.carbon.contracts.resale_contract_preparing_modal.cta",
          )}
        </Button>
      )}
    />
  </Modal.Root>
);

export default ResaleContractSentSuccess;
