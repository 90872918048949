import { Button } from "@ag/design-system/atoms";
import { Modal } from "@ag/design-system/organisms";
import I18n from "@ag/i18n";

const AddFieldsUnavailable = () => (
  <>
    <Modal.Content>{I18n.t("js.carbon.add_fields_unavailable")}</Modal.Content>

    <Modal.Footer
      render={onClose => (
        <Button onClick={onClose}>{I18n.t("js.shared.close")}</Button>
      )}
    />
  </>
);

export default AddFieldsUnavailable;
