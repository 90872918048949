import { Modal } from "@ag/design-system/organisms";
import I18n from "@ag/i18n";

import { ModalContentProps } from "~components/GlobalModal";

import FieldValidationManagement from "./FieldValidationManagement";

export type Extras = {
  harvestYear: number;
};

const CreateValidationRequestForAnotherYear = ({
  extras,
  isOpen,
  onClose,
}: ModalContentProps) => {
  const harvestYear = Number((extras as Extras)?.harvestYear);

  return (
    <Modal.Root isOpen={isOpen} onRequestClose={onClose}>
      <Modal.Title>
        {`${I18n.t("js.carbon.apply_for_validation")} ${harvestYear}`}
      </Modal.Title>

      <Modal.Content>
        <FieldValidationManagement
          harvestYear={harvestYear}
          onClose={onClose}
        />
      </Modal.Content>
    </Modal.Root>
  );
};

export default CreateValidationRequestForAnotherYear;
