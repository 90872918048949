import { useContext, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { usePreviousImmediate } from "rooks";

import { AppHistoryContext } from "~contexts/app-history";

const STACK_SIZE_LIMIT = 100;

export const useAppHistory = () => {
  const {
    historyStack,
    addPage,
    removeFirstPage,
    removeLastPage,
    updateLastPage,
    resetStack,
  } = useContext(AppHistoryContext);

  const { pathname, search, state } = useLocation();
  const navigate = useRef(useNavigate());

  const previousPathname = usePreviousImmediate(pathname);
  const previousSearch = usePreviousImmediate(search);

  const isLoginPage = pathname.includes("/login");
  const isRouteChanging = pathname !== previousPathname;
  const isSearchUpdating = !isRouteChanging && search !== previousSearch;

  /**
   * When doing a redirect we can pass additional properties to the location state:
   * popHistory   - will pop the history stack instead of pushing a new item
   * skipHistory  - will skip adding the item to the history stack
   */
  const { popHistory, skipHistory } = (state || {}) as Record<string, any>; // "any" is declared in react router

  useEffect(() => {
    // Don't append workspaces roots to the history stack
    if (pathname === "/" || ["/carbon"].includes(pathname)) {
      return;
    }

    if (isLoginPage) {
      resetStack();

      return;
    }

    if (skipHistory) {
      // Reset route state
      navigate.current(pathname + search);

      return;
    }

    if (popHistory) {
      removeLastPage();

      // Reset route state
      navigate.current(pathname + search);

      return;
    }

    if (isRouteChanging) {
      addPage(pathname + search);

      return;
    }

    if (isSearchUpdating) {
      updateLastPage(pathname + search);

      return;
    }
  }, [
    pathname,
    search,
    popHistory,
    skipHistory,
    isLoginPage,
    isRouteChanging,
    isSearchUpdating,
    addPage,
    removeLastPage,
    resetStack,
    updateLastPage,
  ]);

  // Keep the history stack at a given size
  useEffect(() => {
    if (historyStack.length > STACK_SIZE_LIMIT) {
      removeFirstPage();
    }
  }, [historyStack, removeFirstPage]);
};
