import { typography } from "@ag/design-system/tokens";

import * as styles from "./form-section.css";

type Props = {
  title: string;
  description?: string;
  children: React.ReactElement<typeof Card> | React.ReactElement<typeof Card>[];
};

const FormSection = ({ title, description, children }: Props) => {
  return (
    <div className={styles.section}>
      <h4 className={typography.h4}>{title}</h4>

      {description && <p className={typography.p1}>{description}</p>}

      {children}
    </div>
  );
};

const Card = ({ children }: React.PropsWithChildrenRequired) => (
  <div className={styles.card}>{children}</div>
);

export default {
  Root: FormSection,
  Card,
};
