import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback, useRef } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { Button } from "@ag/design-system/atoms";
import { Modal } from "@ag/design-system/organisms";
import { typography } from "@ag/design-system/tokens";
import I18n from "@ag/i18n";
import { ToastNotification } from "@ag/utils/services";

import { useSessionContext } from "~features/authentication";
import { useAddFarmMutation } from "~features/farm/api/add-farm";
import { ampli } from "~lib/ampli";

import FarmForm, {
  FarmFormValues,
  farmFormSchema,
} from "./farm-form/farm-form";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const AddFarmModal = ({ isOpen, onClose }: Props) => {
  const navigate = useRef(useNavigate());

  const { currentUser } = useSessionContext();

  const addFarmMutation = useAddFarmMutation();

  const {
    control,
    register,
    formState: { errors },
    handleSubmit: handleFormSubmit,
  } = useForm<FarmFormValues>({
    defaultValues: {
      countryCode: currentUser?.company?.address.countryCode,
    },
    resolver: zodResolver(
      farmFormSchema(currentUser?.company?.address.countryCode),
    ),
  });

  const handleAddFarm = useCallback(
    (data: FarmFormValues) => {
      addFarmMutation.mutate(data, {
        onSuccess: newFarm => {
          ToastNotification.success(
            I18n.t(
              "js.farmer.carbon.farmer_dashboard.add_farm_modal.success_notification",
            ),
          );

          ampli.farmAdded();

          onClose();

          navigate.current(`/carbon/farmer/farm/${newFarm.id}`);
        },
      });
    },
    [addFarmMutation, onClose],
  );

  return (
    <Modal.Root isOpen={isOpen} onRequestClose={onClose}>
      <Modal.Header>
        <Modal.Title>
          {I18n.t("js.farmer.farm_form.add_farm.title")}
        </Modal.Title>
      </Modal.Header>

      <Modal.Content>
        <p className={typography.p1}>
          {I18n.t("js.farmer.farm_form.add_farm.description")}
        </p>

        <FarmForm errors={errors} register={register} control={control} />
      </Modal.Content>

      <Modal.Footer
        render={() => (
          <>
            <Button variant="secondary" onClick={onClose}>
              {I18n.t("js.shared.cancel")}
            </Button>

            <Button onClick={handleFormSubmit(handleAddFarm)}>
              {I18n.t("js.farmer.farm_form.add_farm.title")}
            </Button>
          </>
        )}
      />
    </Modal.Root>
  );
};

export default AddFarmModal;
