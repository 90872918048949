import groupBy from "lodash/groupBy";

import { Field } from "~queries/carbon/fields";

type GetGroupedFieldsArgs = {
  fieldsToBeSubmitted: Record<string, boolean>;
  submittableFields: Field[] | null;
};

type GroupedFields = {
  fieldsToValidateGrouped: Record<string, Field[]> | null;
  fieldsNotToValidateGrouped: Record<string, Field[]> | null;
};

export const getGroupedFields = ({
  submittableFields,
  fieldsToBeSubmitted,
}: GetGroupedFieldsArgs): GroupedFields => {
  const fieldsToValidate = submittableFields?.filter(
    field => fieldsToBeSubmitted[field.id],
  );

  const fieldsNotToValidate = submittableFields?.filter(
    field => !fieldsToBeSubmitted[field.id],
  );

  const fieldsToValidateGrouped = groupBy(
    fieldsToValidate,
    field => field.carbonFarmId,
  );

  const fieldsNotToValidateGrouped = groupBy(
    fieldsNotToValidate,
    field => field.carbonFarmId,
  );

  return {
    fieldsToValidateGrouped,
    fieldsNotToValidateGrouped,
  };
};
