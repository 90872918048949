import axios from "axios";
import { useMutation } from "react-query";

import { ToastNotification } from "@ag/utils/services";

export type UpdateFreightAndTradeSettingsData = Partial<{
  totalFieldsSizeHa: number;
  preferredCurrency: string;
}>;

const updateFreightAndTradeSettings = async (
  userId: string,
  data: UpdateFreightAndTradeSettingsData,
) => {
  await axios.put(`/api/v1/users/${userId}/freight-and-trade-setting`, data, {
    apiSource: "node-auth",
  });
};

export const useUpdateFreightAndTradeSettingsMutation = (
  userId: string | undefined,
) =>
  useMutation(
    (data: UpdateFreightAndTradeSettingsData) => {
      if (!userId) {
        return Promise.reject("UserId was not provided");
      }

      return updateFreightAndTradeSettings(userId, data);
    },
    {
      onError: error => {
        ToastNotification.error(error);
      },
    },
  );
