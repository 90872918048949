import { useAtom } from "jotai";
import { Offline } from "react-detect-offline";

import { Icon } from "@ag/design-system/assets";
import I18n from "@ag/i18n";

import { isOnlineAtom } from "~features/offline-mode";

import * as styles from "./offline-indicator.css";

export const OfflineIndicator = () => {
  const [_, setIsOnline] = useAtom(isOnlineAtom);

  return (
    <Offline polling={false} onChange={setIsOnline}>
      <div className={styles.root}>
        <h4 className={styles.header}>
          <Icon className={styles.icon} name="wifi-slash" />
          {I18n.t("js.offline.you_are_offline")}
        </h4>

        {I18n.t("js.offline.please_check_connection")}
      </div>
    </Offline>
  );
};
