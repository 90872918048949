import { z } from "zod";

export const FarmSchema = z.object({
  id: z.string(),
  name: z.string(),
  numberOfFields: z.number(),
  numberOfValidatedFields: z.number(),
  totalHectares: z.string(),

  carbonCountry: z.object({
    id: z.string(),
    code: z.string(),
    name: z.string(),
  }),
});

export type Farm = z.infer<typeof FarmSchema>;

export const Legacy_FarmSchema = z.object({
  id: z.number(),
  name: z.string(),
  numberOfValidatedFields: z.string(),
  numberOfFields: z.string(),
  totalHectars: z.string(),
  carbonAddress: z.object({
    id: z.number(),
    address: z.string(),
    checksum: z.string(),
    city: z.string(),
    position: z.string(),
    zip_code: z.string(),
    countryCode: z.string(),
    carbonCountry: z.object({
      id: z.number(),
      code: z.string(),
      name: z.string(),
    }),
  }),
});

export type Legacy_Farm = z.infer<typeof Legacy_FarmSchema>;
