import axios from "axios";
import { useMutation } from "react-query";

import { ToastNotification } from "@ag/utils/services";

const sendResetPasswordRequest = async (email: string) => {
  await axios.post(
    "/api/v1/auth/reset-password-request",
    { email },
    { apiSource: "node-auth" },
  );
};

export const useSendResetPasswordRequestMutation = () =>
  useMutation(sendResetPasswordRequest, {
    onError: error => {
      ToastNotification.error(error);
    },
  });
