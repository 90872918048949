import JsApiMakerI18n from "i18n-js";
import moment from "moment";

import { Locale, updateTranslations } from "@ag/i18n";
import { loadTranslations } from "@ag/i18n/app";

export const setupI18n = () => {
  const locale = document.querySelector("html")?.getAttribute("lang");

  if (locale) {
    moment.locale(locale);
  }

  loadTranslations(Locale.En).then(translations =>
    updateTranslations(Locale.En, translations, JsApiMakerI18n),
  );
};
