export enum AdditionalInformationAttribute {
  Comment = "otherInformationComment",
}

export type AdditionalInformationV2022Data = {
  [AdditionalInformationAttribute.Comment]: string | null;
};

export enum AdditionalInformationOptionalAttribute {
  Comment = AdditionalInformationAttribute.Comment,
}
