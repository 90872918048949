import { useAtom } from "jotai";
import { Controller, useFormContext } from "react-hook-form";

import { ButtonSelect } from "@ag/design-system/molecules";
import { ButtonSelectField } from "@ag/form-fields";
import I18n from "@ag/i18n";

import FormSection from "~components/form-section";

import { isDisabledAtom } from "../atoms/is-disabled-atom";
import { type FieldDefinitionData } from "../schemas/field-definition-form";
import { SoilDrainage } from "../types/field-definition-data";

const FieldDrainageFormSection = () => {
  const [isDisabled] = useAtom(isDisabledAtom);

  const {
    control,
    formState: { errors },
  } = useFormContext<FieldDefinitionData>();

  return (
    <FormSection.Root
      title={I18n.t(
        "js.carbon.field_details.field_definition.questionnaire.drainage.title",
      )}
    >
      <FormSection.Card>
        <Controller
          name="soilDrainage"
          control={control}
          render={({ field }) => (
            <ButtonSelectField
              {...field}
              label={I18n.t("js.carbon.drainage")}
              tooltip={I18n.t(
                "js.carbon.field_details.field_definition.questionnaire.drainage.tip",
              )}
              isRequired
              testId="soil-drainage"
              error={errors.soilDrainage}
              isDisabled={isDisabled}
            >
              <ButtonSelect.Option value={SoilDrainage.GOOD}>
                {I18n.t(
                  "models.concerns.carbon_field_definitions.soil_concern.soil_drainages.good",
                )}
              </ButtonSelect.Option>

              <ButtonSelect.Option value={SoilDrainage.POOR}>
                {I18n.t(
                  "models.concerns.carbon_field_definitions.soil_concern.soil_drainages.poor",
                )}
              </ButtonSelect.Option>
            </ButtonSelectField>
          )}
        />
      </FormSection.Card>
    </FormSection.Root>
  );
};

export default FieldDrainageFormSection;
