import axios from "axios";
import { UseQueryOptions, useQuery } from "react-query";

import { CommonResponse } from "@ag/utils/types";

import { Contract, ContractSchema } from "../entities/contract";

export const generateContractQueryKey = (
  contractId: string | null | undefined,
) => ["contract", contractId].filter(Boolean);

type GetContractParams = {
  forceRefresh?: boolean;
};

export const getContract = async (
  contractId: string | null | undefined,
  params?: GetContractParams,
) => {
  if (!contractId) return undefined;

  const response = await axios.get<CommonResponse<Contract>>(
    `/api/v1/contracts/${contractId}`,
    {
      params,
      schema: ContractSchema,
      apiSource: "node-carbon",
    },
  );

  return response.data.success!.data;
};

export const useContractQuery = (
  contractId: string | null | undefined,
  options?: Pick<UseQueryOptions<Contract>, "enabled" | "onSuccess">,
) =>
  useQuery(
    generateContractQueryKey(contractId),
    () => getContract(contractId),
    options,
  );
