import { useParams } from "react-router-dom";

export const useQueryParam = (key: string): string => {
  const params = useParams();
  const param = params[key];

  if (!param) {
    throw Error(`${key} not found in URL params.`);
  }

  return param;
};
