import omit from "lodash/omit";
import { Suspense, lazy, useLayoutEffect, useRef } from "react";
import { Navigate, Route, useLocation, useNavigate } from "react-router-dom";

import I18n from "@ag/i18n";

import FullPageSpinner from "~components/FullPageSpinner";
import { AuthorizedRoute, useSessionContext } from "~features/authentication";
import { SentryRoutes } from "~lib/sentry";

import Page404 from "./404";
import { useUserRedirection } from "./hooks";
import Unsupported from "./unsupported";

type RedirectRouteState = {
  skipRedirectLogic?: boolean;
};

const MissingProfileInformation = lazy(
  () =>
    import(
      /* webpackChunkName: "missing-profile-information" */ "./missing-profile-information"
    ),
);
const InitialCarbonEarnings = lazy(
  () =>
    import(
      /* webpackChunkName: "initial-carbon-earnings" */ "./initial-carbon-earnings"
    ),
);
const Login = lazy(() => import(/* webpackChunkName: "login" */ "./login"));
const Carbon = lazy(() => import(/* webpackChunkName: "carbon" */ "./carbon"));
const Actuals = lazy(
  () => import(/* webpackChunkName: "actuals" */ "./actuals/actuals"),
);
const Bulk = lazy(
  () => import(/* webpackChunkName: "bulk" */ "./carbon/pages/bulk"),
);
const Registration = lazy(
  () => import(/* webpackChunkName: "registration" */ "./registration"),
);
const ResetPassword = lazy(
  () => import(/* webpackChunkName: "reset-password" */ "./reset-password"),
);
const MyAccount = lazy(
  () => import(/* webpackChunkName: "my-account" */ "./my-account/my-account"),
);
const Confirmation = lazy(
  () => import(/* webpackChunkName: "confirmation" */ "./confirmation"),
);
const File = lazy(() => import(/* webpackChunkName: "file" */ "./file"));
const Signup = lazy(
  () => import(/* webpackChunkName: "signup" */ "./signup/page"),
);

const Views = () => {
  const navigate = useRef(useNavigate());
  const { pathname, state } = useLocation();

  const userRedirection = useUserRedirection();

  const { isLoading: isSessionLoading } = useSessionContext();

  // Handle user redirect before the first render
  useLayoutEffect(() => {
    const redirectState = state as RedirectRouteState;
    const skipRedirectLogic = redirectState?.skipRedirectLogic;

    if (isSessionLoading) return;

    if (
      !skipRedirectLogic &&
      userRedirection &&
      userRedirection.to !== pathname // to avoid infinity loop as userRedirection always return new object
    ) {
      navigate.current(userRedirection.to, {
        ...userRedirection.options,
        state: {
          skipHistory: userRedirection.options?.replace,
        },
      });
      return;
    }

    if (skipRedirectLogic) {
      if (!state) return;

      const newState = omit(redirectState, "skipRedirectLogic");

      // remove the skipRedirectLogic from the route state to restore redirect logic
      navigate.current(pathname, {
        state: newState,
        replace: true,
      });
    }
  }, [pathname, state, userRedirection, isSessionLoading]);

  return (
    <Suspense fallback={<FullPageSpinner />}>
      <SentryRoutes>
        <Route path="login" element={<Login />} />
        <Route
          path="confirmation/*"
          element={
            <AuthorizedRoute>
              <Confirmation />
            </AuthorizedRoute>
          }
        />

        <Route
          path="/initial-carbon-earnings"
          element={<InitialCarbonEarnings />}
        />

        <Route
          path="missing-profile-information"
          element={
            <AuthorizedRoute>
              <MissingProfileInformation />
            </AuthorizedRoute>
          }
        />

        <Route path="registration/*" element={<Registration />} />
        <Route path="reset-password/*" element={<ResetPassword />} />

        <Route
          path="/session-check"
          element={
            <AuthorizedRoute>
              <Navigate to="/" replace />
            </AuthorizedRoute>
          }
        />

        <Route
          path="account"
          element={
            <AuthorizedRoute>
              <MyAccount />
            </AuthorizedRoute>
          }
        />

        <Route
          path="carbon/*"
          element={
            <AuthorizedRoute>
              <Carbon />
            </AuthorizedRoute>
          }
        />

        <Route
          path="actuals/*"
          element={
            <AuthorizedRoute>
              <Actuals />
            </AuthorizedRoute>
          }
        />

        <Route
          path="bulk/*"
          element={
            <AuthorizedRoute>
              <Bulk />
            </AuthorizedRoute>
          }
        />

        <Route
          path="file"
          element={
            <AuthorizedRoute>
              <File />
            </AuthorizedRoute>
          }
        />

        <Route path="signup/*" element={<Signup />} />

        <Route path="unsupported" element={<Unsupported />} />

        {/* Root page doesn't have any component - Views will redirect user to the proper scope */}
        <Route path="/" element={<div />} />

        <Route
          path="*"
          element={
            <Page404
              buttonLink="/"
              buttonText={I18n.t("js.farmer.go_to_homepage")}
            />
          }
        />
      </SentryRoutes>
    </Suspense>
  );
};

export default Views;
