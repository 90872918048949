import axios from "axios";
import { useMutation } from "react-query";

import { ToastNotification } from "@ag/utils/services";

export type CreateUserData = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  acceptedTerms: boolean;
  acceptedNewsletter?: boolean;
  phoneNumber: string;
  locale: string;
  countryCode: string;
  preferredCurrency?: string;
};

const createUser = async (data: CreateUserData) => {
  await axios.post(`/api/v1/users`, data, {
    apiSource: "node-auth",
  });
};

export const useCreateUserMutation = () =>
  useMutation(createUser, {
    onError: error => {
      ToastNotification.error(error);
    },
  });
