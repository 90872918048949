import { FieldError } from "react-hook-form";
import { z } from "zod";

import {
  MixedFertiliserSchema,
  NoneFertiliserSchema,
  OrganicFertiliserSchema,
  SyntheticFertiliserSchema,
} from "../schemas/field-definition-form";

export enum FertiliserType {
  ORGANIC = "organic",
  SYNTHETIC = "synthetic",
  MIXED = "mixed",
  NONE = "none",
}

export type OrgainicFertiliser = z.infer<typeof OrganicFertiliserSchema>;
export type SyntheticFertiliser = z.infer<typeof SyntheticFertiliserSchema>;
export type MixedFertiliser = z.infer<typeof MixedFertiliserSchema>;
export type NoneFertiliser = z.infer<typeof NoneFertiliserSchema>;

export type Fertiliser =
  | OrgainicFertiliser
  | SyntheticFertiliser
  | MixedFertiliser
  | NoneFertiliser;

export type FertiliserErrors = Partial<{
  [key in
    | keyof OrgainicFertiliser
    | keyof SyntheticFertiliser
    | keyof MixedFertiliser
    | keyof NoneFertiliser]: FieldError;
}>;
