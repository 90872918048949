import { css } from "@emotion/core";

import { colors } from "@ag/design-system/tokens";

export const intercomStyles = css`
  .intercom-launcher {
    && {
      background: ${colors.accent[200]};
    }
  }
`;
