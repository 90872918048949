import { RefinementCtx, z } from "zod";

import I18n from "@ag/i18n";

import { FertiliserType } from "../types/fertiliser-type";
import {
  CoverCrops,
  ExemptionReason,
  FuelType,
  LimingType,
  ResidueManagement,
  SoilDrainage,
  SyntheticPhosphorusType,
  SyntheticPotassiumType,
  Tilling,
} from "../types/field-definition-data";

const OrganicValues = () =>
  z.object({
    organicType: z.string().optional(),
    organicAmount: z.number().optional(),
    organicCarbonPercentage: z
      .number()
      .min(0, I18n.t("js.shared.min", { min: 0 }))
      .max(100, I18n.t("js.shared.max", { max: 100 }))
      .optional(),
    organicNitrogenPercentage: z
      .number()
      .min(0, I18n.t("js.shared.min", { min: 0 }))
      .max(100, I18n.t("js.shared.max", { max: 100 }))
      .optional(),
    organicDryMatterPercentage: z
      .number()
      .min(0, I18n.t("js.shared.min", { min: 0 }))
      .max(100, I18n.t("js.shared.max", { max: 100 }))
      .optional(),
  });

const SyntheticValues = () =>
  z.object({
    syntheticNitrogenApplicationRate: z
      .number()
      .min(0, I18n.t("js.shared.min", { min: 0 }))
      .optional(),
    syntheticPhosphorusApplicationRate: z
      .number()
      .min(0, I18n.t("js.shared.min", { min: 0 }))
      .optional(),
    syntheticPhosphorusType: z.nativeEnum(SyntheticPhosphorusType).optional(),
    syntheticPotassiumApplicationRate: z
      .number()
      .min(0, I18n.t("js.shared.min", { min: 0 }))
      .optional(),
    syntheticPotassiumType: z.nativeEnum(SyntheticPotassiumType).optional(),
  });

export const OrganicFertiliserSchema = z
  .object({
    type: z.enum([FertiliserType.ORGANIC]).optional(),
  })
  .merge(OrganicValues());

export const SyntheticFertiliserSchema = z
  .object({
    type: z.enum([FertiliserType.SYNTHETIC]).optional(),
  })
  .merge(SyntheticValues());

export const MixedFertiliserSchema = z
  .object({
    type: z.enum([FertiliserType.MIXED]).optional(),
  })
  .merge(OrganicValues())
  .merge(SyntheticValues());

export const NoneFertiliserSchema = z.object({
  type: z.enum([FertiliserType.NONE]).optional(),
});

export const FertiliserSchema = z.union([
  SyntheticFertiliserSchema,
  OrganicFertiliserSchema,
  MixedFertiliserSchema,
  NoneFertiliserSchema,
]);

export const FieldPracticeSchema = () =>
  z.object({
    exemptionReason: z.nativeEnum(ExemptionReason).optional(),
    cropType: z.string().optional(),
    totalYield: z
      .number()
      .min(0, I18n.t("js.shared.min", { min: 0 }))
      .optional()
      .nullable(),
    tilling: z.nativeEnum(Tilling).optional(),
    coverCrops: z.nativeEnum(CoverCrops).optional(),
    residueManagement: z.nativeEnum(ResidueManagement).optional(),
    fuelType: z.nativeEnum(FuelType).optional(),
    fuelAmount: z
      .number()
      .min(0, I18n.t("js.shared.min", { min: 0 }))
      .nullable()
      .optional(),
    fertilisers: z.array(FertiliserSchema),
  });

export type FieldPractice = z.infer<ReturnType<typeof FieldPracticeSchema>>;

export const validation = (
  {
    exemptionReason,
    cropType,
    residueManagement,
    tilling,
    coverCrops,
    fuelType,

    fertilisers,
  }: Partial<FieldPractice>,
  ctx: RefinementCtx,
) => {
  // Either is mandatory
  if (!cropType && !exemptionReason) {
    ctx.addIssue({
      message: I18n.t("js.shared.required"),
      code: z.ZodIssueCode.custom,
      path: ["cropType"],
    });
  }

  // MANDATORY if cropType
  if (cropType) {
    Object.entries({
      residueManagement,
      tilling,
      coverCrops,
      fuelType,
    }).forEach(([key, value]) => {
      if (!value) {
        ctx.addIssue({
          message: I18n.t("js.shared.required"),
          code: z.ZodIssueCode.custom,
          path: [key],
        });
      }
    });

    // Must include at least 1 fertiliser type
    if (!fertilisers || !fertilisers[0].type) {
      ctx.addIssue({
        message: I18n.t("js.shared.required"),
        code: z.ZodIssueCode.custom,
        path: [`fertilisers.0.type`],
      });
    }

    // Organic type mandatory if organic or mixed selected
    fertilisers?.forEach((fertiliser, index) => {
      if (
        fertiliser.type === FertiliserType.ORGANIC ||
        fertiliser.type === FertiliserType.MIXED
      ) {
        if (!fertiliser.organicType) {
          ctx.addIssue({
            message: I18n.t("js.shared.required"),
            code: z.ZodIssueCode.custom,
            path: [`fertilisers.${index}.organicType`],
          });
        }

        if (fertiliser.organicType === "OTHER") {
          if (!fertiliser.organicCarbonPercentage) {
            ctx.addIssue({
              message: I18n.t("js.shared.required"),
              code: z.ZodIssueCode.custom,
              path: [`fertilisers.${index}.organicCarbonPercentage`],
            });
          }

          if (!fertiliser.organicDryMatterPercentage) {
            ctx.addIssue({
              message: I18n.t("js.shared.required"),
              code: z.ZodIssueCode.custom,
              path: [`fertilisers.${index}.organicDryMatterPercentage`],
            });
          }

          if (!fertiliser.organicNitrogenPercentage) {
            ctx.addIssue({
              message: I18n.t("js.shared.required"),
              code: z.ZodIssueCode.custom,
              path: [`fertilisers.${index}.organicNitrogenPercentage`],
            });
          }
        }
      }
    });

    // If application rate exist type is required
    fertilisers?.forEach((fertiliser, index) => {
      if (
        fertiliser.type === FertiliserType.SYNTHETIC ||
        fertiliser.type === FertiliserType.MIXED
      ) {
        if (
          fertiliser?.syntheticPhosphorusApplicationRate !== undefined &&
          fertiliser?.syntheticPhosphorusType === undefined
        ) {
          ctx.addIssue({
            message: I18n.t("js.shared.required"),
            code: z.ZodIssueCode.custom,
            path: [`fertilisers.${index}.syntheticPhosphorusType`],
          });
        }

        if (
          fertiliser?.syntheticPotassiumApplicationRate !== undefined &&
          fertiliser?.syntheticPotassiumType === undefined
        ) {
          ctx.addIssue({
            message: I18n.t("js.shared.required"),
            code: z.ZodIssueCode.custom,
            path: [`fertilisers.${index}.syntheticPotassiumType`],
          });
        }
      }
    });

    return;
  }
};

export const FieldDefinitionFormSchema = () =>
  z.object({
    soilDrainage: z.nativeEnum(SoilDrainage),
    soilMgmtHistoricConversion: z.boolean(),
    questionnaire: z.object({
      nitrificationInhibitorsConsistentlyTheLastFiveYears: z.boolean(),
      cropProtectionInAnyOfTheLastFiveYears: z.boolean(),
    }),
    verraQuestionnaire: z.object({
      hectaresLeased: z.number().min(0, I18n.t("js.shared.min", { min: 0 })),
      hectaresLostInNextFiveYears: z
        .number()
        .min(0, I18n.t("js.shared.min", { min: 0 })),
      fieldArableInPastTenYears: z.boolean(),

      limingType: z.nativeEnum(LimingType),
      limingFrequency: z
        .number()
        .min(0, I18n.t("js.shared.min", { min: 0 }))
        .optional(),
      limingApplicationRate: z
        .number()
        .min(0, I18n.t("js.shared.min", { min: 0 }))
        .optional(),

      fieldPracticesOneYearAgo: FieldPracticeSchema()
        .partial()
        .superRefine(validation),
      fieldPracticesTwoYearsAgo: FieldPracticeSchema()
        .partial()
        .superRefine(validation),
      fieldPracticesThreeYearsAgo: FieldPracticeSchema()
        .partial()
        .superRefine(validation),
      fieldPracticesFourYearsAgo: FieldPracticeSchema()
        .partial()
        .superRefine(validation),
      fieldPracticesFiveYearsAgo: FieldPracticeSchema()
        .partial()
        .superRefine(validation),
    }),
  });

export type FieldDefinitionData = z.infer<
  ReturnType<typeof FieldDefinitionFormSchema>
>;
