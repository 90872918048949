const envs = [
  "REACT_APP_AMPLITUDE_API_KEY",
  "REACT_APP_API_NODE_AUTH_ACCESS_TOKEN",
  "REACT_APP_API_NODE_AUTH_BASE_URL",
  "REACT_APP_API_NODE_CARBON_ACCESS_TOKEN",
  "REACT_APP_API_NODE_CARBON_BASE_URL",
  "REACT_APP_API_NODE_FLATFILE_BASE_URL",
  "REACT_APP_API_NODE_FLATFILE_ACCESS_TOKEN",
  "REACT_APP_API_NODE_DATA_COLLECTION_BASE_URL",
  "REACT_APP_API_NODE_DATA_COLLECTION_ACCESS_TOKEN",
  "REACT_APP_API_RUBY_ACCESS_TOKEN",
  "REACT_APP_API_RUBY_BASE_URL",
  "REACT_APP_APP_ENVIRONMENT",
  "REACT_APP_API_DE_CROP_BASE_URL",
  "REACT_APP_CALENDLY_VALIDATION_MEETING_URL",
  "REACT_APP_EARNINGS_WIDGET_URL",
  "REACT_APP_FACEBOOK_APP_ID",
  "REACT_APP_GOOGLE_MAPS_API_KEY",
  "REACT_APP_GTM_ID",
  "REACT_APP_INTERCOM_APP_ID",
  "REACT_APP_MAINTENANCE_MODE",
  "REACT_APP_SENTRY_DSN",
  "REACT_APP_WEBSITE_MARKETING_TERMS_URL",
  "REACT_APP_WEBSITE_POLICY_URL",
  "REACT_APP_WEBSITE_TERMS_URL",
  "REACT_APP_WEBSITE_URL",
  "REACT_APP_FLATFILE_PUBLISHABLE_KEY",
  "REACT_APP_FLATFILE_ENVIRONMENT_ID",
  "REACT_APP_COOKIEBOT_KEY",
] as const;

type WindowWithEnvs = {
  [K in (typeof envs)[number]]: string | undefined;
};

declare global {
  interface Window extends WindowWithEnvs {}
}

export const env = envs.reduce(
  (acc, env) => {
    acc[env] = window[env];
    return acc;
  },
  {} as Record<(typeof envs)[number], string | undefined>,
);
