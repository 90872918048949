import { ValidationErrors } from "final-form";

import I18n from "@ag/i18n";
import { KeyMap, LabelValue } from "@ag/utils/types";

import { Farm } from "~features/farm";
import { MAX_SIZE_HA, MIN_SIZE_HA } from "~views/carbon/constants";

import { CoreFieldDataAttribute } from "./types";

type CoreFieldDataError = KeyMap<string | KeyMap<string>>;

type ValidateArgs = {
  values: ValidationErrors;
};

export function validate({ values }: ValidateArgs): CoreFieldDataError {
  const errors: CoreFieldDataError = {};

  if (!values) return errors;

  if (!values[CoreFieldDataAttribute.Name]) {
    errors[CoreFieldDataAttribute.Name] = I18n.t(
      "js.carbon.enter_enrollment_code_value",
    );
  }

  if (!values[CoreFieldDataAttribute.CarbonFarmId]) {
    errors[CoreFieldDataAttribute.CarbonFarmId] = I18n.t(
      "js.carbon.enter_nearest_address",
    );
  }

  if (!values[CoreFieldDataAttribute.SizeHa]) {
    errors[CoreFieldDataAttribute.SizeHa] = I18n.t(
      "js.carbon.enter_your_field_size",
    );
  }

  if (values[CoreFieldDataAttribute.SizeHa] < MIN_SIZE_HA) {
    errors[CoreFieldDataAttribute.SizeHa] = I18n.t(
      "js.carbon.field.error.min_size_ha",
      {
        minSizeHa: MIN_SIZE_HA,
      },
    );
  }

  if (values[CoreFieldDataAttribute.SizeHa] > MAX_SIZE_HA) {
    errors[CoreFieldDataAttribute.SizeHa] = I18n.t(
      "js.carbon.field.error.max_size_ha",
      {
        maxSizeHa: MAX_SIZE_HA,
      },
    );
  }

  return errors;
}

export const mapDeliveryAddresses = (
  farms: Farm[] | undefined,
): LabelValue<string>[] | [] => {
  if (!farms) {
    return [];
  }

  const deliveryAddressMapped = farms.map(farm => ({
    label: farm.name,
    value: farm.id,
  }));

  return deliveryAddressMapped;
};
