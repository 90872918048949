import { z } from "zod";

import { Locale } from "@ag/i18n";
import { LatLngSchema } from "@ag/map/types";
import { UserRole, UserValidationState } from "@ag/utils/types";

const FreightAndTradeSettingSchema = z.object({
  acceptedNewsletter: z.boolean(),
  acceptedTerms: z.boolean(),
  preferredCurrency: z.string(),
});

const AddressSchema = z.object({
  position: z.nullable(LatLngSchema),
  address: z.string(),
  checksum: z.string(),
  city: z.string(),
  countryCode: z.string(),
  id: z.string(),
  name: z.string().nullable(),
});

const CompanySchema = z.object({
  name: z.string(),
  vatValidatedAt: z.nullable(z.string()),
  address: AddressSchema,
});

const ProfileSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  name: z.string(),
  phoneNumber: z.string(),
});

export const CurrentUserSchema = z.object({
  id: z.string(),
  email: z.string(),
  validationState: z.nativeEnum(UserValidationState),
  startupPage: z.nullable(z.string()),
  locale: z.nativeEnum(Locale),
  confirmedAt: z.string(),
  profile: ProfileSchema,
  freightAndTradeSetting: FreightAndTradeSettingSchema,
  company: z.nullable(CompanySchema),
  roles: z.array(z.nativeEnum(UserRole)),
});

export type CurrentUser = z.infer<typeof CurrentUserSchema>;
