import styled from "@emotion/styled";

import { colorsObject } from "@ag/design-system/tokens";

type SFieldItemProps = {
  isActive?: boolean;
  dot?: boolean;
  isDeactivated?: boolean;
  isSkeleton?: boolean;
  isFieldsValidated?: boolean;
};

type SDescriptionProps = {
  noMargin?: boolean;
  marginTop?: number;
};

type SListTitleProps = {
  isSkeleton?: boolean;
};

export const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
  // desired height minus vertical padding
  max-height: calc(90vh - 64px);
`;

export const STitle = styled.h2`
  font-size: 20px;
  line-height: 32px;
  font-weight: bold;
`;

export const SDescription = styled.div`
  margin-bottom: 16px;
  margin-top: 16px;
  flex-shrink: 0;

  ${({ noMargin, marginTop }: SDescriptionProps): string => {
    let styles = ``;

    if (noMargin) {
      styles += `
        margin-bottom: 0;
      `;
    }

    if (marginTop) {
      styles += `
        margin-top: ${marginTop}px;
      `;
    }

    return styles;
  }};
`;

export const SStepFooter = styled.footer`
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
  gap: 8px;

  /* offset the parent modal padding */
  /* TODO: create shared Modal footer component */
  margin: 24px -32px -32px;
  padding: 16px 32px;

  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.05);
`;

export const SAddresses = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0 16px;
`;

export const SAddress = styled.div`
  color: ${colorsObject.grey[700]};

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
`;

export const SListTitleWrapper = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;

  background-color: ${colorsObject.grey.white};
`;

export const SListTitle = styled.div`
  background-color: ${colorsObject.grey[200]};
  line-height: 24px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 8px 16px;

  ${({ isSkeleton }: SListTitleProps): string => {
    let styles = ``;

    if (isSkeleton) {
      styles += `
        margin-top: 16px;
      `;
    }

    return styles;
  }}
`;

export const SFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const SFieldItem = styled.div<SFieldItemProps>`
  display: flex;
  justify-content: space-between;

  ${({ isDeactivated, isActive, isSkeleton }) => {
    let styles = ``;

    if (isDeactivated) {
      styles += `
        cursor: pointer;
        color: ${colorsObject.grey[900]};
      `;
    }

    if (isActive) {
      styles += `
        color: ${colorsObject.grey[900]};
        cursor: pointer;

        &::before {
          background: ${colorsObject.accent[100]};
        }
      `;
    }

    if (isSkeleton) {
      styles += `
         margin-top: 12px;
      `;
    }

    return styles;
  }}
`;

export const SStepContent = styled.section`
  flex: 1;
`;

export const SAddressName = styled.div`
  text-wrap: nowrap;
`;

export const SAddressLine = styled.hr`
  width: 100%;

  margin: 0;
  padding: 0;

  border: none;
  border-top: 1px solid ${colorsObject.grey[600]};
`;

export const SScrollableContent = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding-bottom: 8px;
`;

export const SAddFieldsContent = styled.div`
  flex-grow: 1;
  overflow-y: auto;
`;
