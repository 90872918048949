/**
 * ENTITIES
 */
export type ValidationRequest = {
  carbonFieldIds: number[];
  carbonUserId: number;
  createdAt: string;
  eventUri: string;
  id: number;
  inviteeUri: string;
  meetingDate: string | null;
  status: ValidationRequestStatus;
  updatedAt: string;
  validatedAt: string | null;
  harvestYear: number;
};

/**
 * RESPONSES
 */

/**
 * QUERY KEY ARGS
 */

/**
 * QUERY ARGS
 */

/**
 * QUERY FUNCTION ARGS
 */
export type ValidationRequestUpdateArgs = {
  carbonFieldIds: string[];
  requestId: number;
};

/**
 * MUTATION FUNCTION ARGS
 */

/**
 * MISC
 */
export enum ValidationRequestStatus {
  New = "new",
  Resolved = "resolved",
}

export enum ValidationStatus {
  New = "new",
  InProgress = "in_progress",
  Validated = "validated",
  Rejected = "rejected",
}
