import { zodResolver } from "@hookform/resolvers/zod";
import { format } from "date-fns";
import { useAtom } from "jotai";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { Icon } from "@ag/design-system/assets";
import { Button } from "@ag/design-system/atoms";
import { typography } from "@ag/design-system/tokens";
import { box } from "@ag/design-system/utils";
import I18n from "@ag/i18n";
import { ToastNotification } from "@ag/utils/services";

import LearnMore from "~views/carbon/pages/field/components/LearnMore";

import { useFieldDefinitionSubmitMutation } from "../api/submit-field-definition";
import { useFieldDefinitionUpdateMutation } from "../api/update-field-definition";
import { isDisabledAtom } from "../atoms/is-disabled-atom";
import { convertFormValuesToApiPayload } from "../helpers/filter-values";
import { getFormDefaultValues } from "../helpers/get-form-default-values";
import { FieldDefinition } from "../schemas/field-definition";
import {
  FieldDefinitionFormSchema,
  type FieldDefinitionData as FieldDefinitionFormType,
} from "../schemas/field-definition-form";
import * as styles from "./field-definition-form.css";
import FieldDrainageFormSection from "./field-drainage-form-section";
import GeneralFieldFormSection from "./general-field-form-section";
import LandRightsFormSection from "./land-rights-form-section";
import LimingFormSection from "./liming-form-section";
import NitrificationInhibitorsFormSection from "./nitrification-inhibitors-form-section";
import PreviousYearsFormSection from "./previous-years-form-section";

type Props = {
  fieldId: string;
  definition: FieldDefinition;
  harvestYear: number;
};

const FieldDefinitionForm = ({ fieldId, definition, harvestYear }: Props) => {
  const [isDisabled, setIsDisabled] = useAtom(isDisabledAtom);

  // Land Rights questions introduced from 2024 harvest year
  const showLandRightsSection = harvestYear >= 2024;

  useEffect(() => {
    setIsDisabled(Boolean(definition.submittedAt));
  }, [definition.submittedAt, setIsDisabled]);

  const fieldDefinitionSubmit = useFieldDefinitionSubmitMutation(
    fieldId,
    definition.id,
    {
      onSuccess: () => {
        ToastNotification.success(
          I18n.t(
            "js.carbon.field_details.field_definition.questionnaire.save_success",
          ),
        );
      },
    },
  );

  const fieldDefinitionUpdate = useFieldDefinitionUpdateMutation(
    fieldId,
    definition.id,
    {
      onSuccess: () => fieldDefinitionSubmit.mutate(),
    },
  );

  const methods = useForm<FieldDefinitionFormType>({
    resolver: zodResolver(FieldDefinitionFormSchema()),
    defaultValues: getFormDefaultValues(definition),
  });

  const updateFieldDefinition = (data: FieldDefinitionFormType) => {
    fieldDefinitionUpdate.mutate(convertFormValuesToApiPayload(data));
  };

  return (
    <FormProvider {...methods}>
      <form
        className={styles.page}
        onSubmit={methods.handleSubmit(updateFieldDefinition)}
      >
        <div className={styles.form}>
          {showLandRightsSection && (
            <>
              <LandRightsFormSection />

              <hr style={{ margin: "32px 0" }} />
            </>
          )}

          <GeneralFieldFormSection />

          <FieldDrainageFormSection />

          <LimingFormSection />

          <NitrificationInhibitorsFormSection />

          <PreviousYearsFormSection harvestYear={harvestYear} />
        </div>

        <div className={styles.submitCol}>
          <div className={styles.submitColSticky}>
            <LearnMore
              description={I18n.t(
                "js.farmer.carbon.field_definition.ana_section_description",
              )}
              modalText={I18n.t(
                "js.farmer.carbon.field_definition.ana_learn_more_modal_description",
              )}
            />
            {isDisabled ? (
              <div className={styles.submittedCard}>
                <Icon name="check" />

                <p className={typography.h5}>
                  {I18n.t(
                    "js.carbon.field_details.field_definition.questionnaire.submitted_on",
                    {
                      date: format(
                        new Date(definition.submittedAt as string),
                        "dd.MM.yyyy",
                      ),
                    },
                  )}
                </p>
              </div>
            ) : (
              <>
                <Button
                  type="submit"
                  width="100%"
                  isLoading={
                    fieldDefinitionUpdate.isLoading ||
                    fieldDefinitionSubmit.isLoading
                  }
                >
                  {I18n.t(
                    "js.carbon.field_details.field_definition.questionnaire.save",
                  )}
                </Button>
                <div className={box({ gap: 8, align: "start" })}>
                  <Icon name="info-circle" />

                  <span className={typography.p3}>
                    {I18n.t(
                      "js.carbon.field_details.field_definition.questionnaire.save_helper",
                    )}
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default FieldDefinitionForm;
