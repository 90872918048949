import { z } from "zod";

import {
  CoverCrops,
  ExemptionReason,
  FieldDefinitionStatus,
  FuelType,
  LimingType,
  ResidueManagement,
  SoilDrainage,
  Tilling,
} from "../types/field-definition-data";
import { FertiliserSchema } from "./field-definition-form";

const PracticeSchema = z.object({
  exemptionReason: z.nativeEnum(ExemptionReason),
  cropType: z.string(),
  totalYield: z.number(),
  tilling: z.nativeEnum(Tilling),
  coverCrops: z.nativeEnum(CoverCrops),
  residueManagement: z.nativeEnum(ResidueManagement),
  fuelType: z.nativeEnum(FuelType),
  fuelAmount: z.number().nullable(),
  fertilisers: z.array(FertiliserSchema),
});

export const BaseFieldDefinitionSchema = z.object({
  id: z.number(),
  baseline: z.string().nullable(),
  status: z.nativeEnum(FieldDefinitionStatus),
  simplified: z.boolean(),
  completedAt: z.string().nullable(),
  submittedAt: z.string().nullable(),
  readonly: z.boolean(),
  completionRate: z.number(),
});

export const FieldDefinitionSchema = BaseFieldDefinitionSchema.merge(
  z.object({
    soilDrainage: z.nativeEnum(SoilDrainage),
    soilSomSpecific: z.number(),
    soilMgmtHistoricConversion: z.boolean(),

    questionnaire: z.object({
      nitrificationInhibitorsConsistentlyTheLastFiveYears: z.boolean(),
      cropProtectionInAnyOfTheLastFiveYears: z.boolean(),
    }),
    verraQuestionnaire: z
      .object({
        hectaresLeased: z.number(),
        hectaresLostInNextFiveYears: z.number(),
        fieldArableInPastTenYears: z.boolean(),

        limingType: z.nativeEnum(LimingType),
        limingFrequency: z.number().optional(),
        limingApplicationRate: z.number().optional(),

        fieldPracticesOneYearAgo: PracticeSchema.partial(),
        fieldPracticesTwoYearsAgo: PracticeSchema.partial(),
        fieldPracticesThreeYearsAgo: PracticeSchema.partial(),
        fieldPracticesFourYearsAgo: PracticeSchema.partial(),
        fieldPracticesFiveYearsAgo: PracticeSchema.partial(),
      })
      .partial()
      .nullable(),
  }),
);

export type FieldDefinition = z.infer<typeof FieldDefinitionSchema>;
