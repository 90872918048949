import { Button } from "@ag/design-system/atoms";
import { Modal } from "@ag/design-system/organisms";
import I18n from "@ag/i18n";

type Props = {
  text: string;
  isModalOpen: boolean;
  onModalClose: () => void;
};

const LearnMoreModal = ({ text, isModalOpen, onModalClose }: Props) => (
  <Modal.Root size="small" isOpen={isModalOpen} onRequestClose={onModalClose}>
    <Modal.Content>
      <p
        className="text-p1"
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      />
    </Modal.Content>

    <Modal.Footer
      render={() => (
        <Button onClick={onModalClose}>{I18n.t("js.shared.ok")}</Button>
      )}
    />
  </Modal.Root>
);

export default LearnMoreModal;
