import { useState } from "react";

import { useFieldsQuery } from "~queries/carbon/fields";
import { useYearsDataQuery } from "~queries/carbon/initial-resources";

import FieldsForValidationList from "./components/FieldsForValidationList";
import FieldsForValidationSkeleton from "./components/Skeleton";
import { SFieldsForValidationModalListContent } from "./styled";
import { SubmitFieldsForValidationListAction } from "./types";

type HandleFieldClickedArgs = {
  fieldId: string;
  action: SubmitFieldsForValidationListAction;
};

export type Props = React.PropsWithChildren<{
  harvestYear: number;
  fieldsToBeSubmittedInitialData?: Record<number, boolean>;
  onChosenFieldsUpdate: (fieldsToBeSubmitted: Record<number, boolean>) => void;
}>;

const FieldsForValidation = ({
  harvestYear,
  children,
  fieldsToBeSubmittedInitialData,
  onChosenFieldsUpdate,
}: Props) => {
  const [fieldsToBeSubmitted, setFieldsToBeSubmitted] = useState<
    Record<number, boolean>
  >(fieldsToBeSubmittedInitialData || {});

  const { data: yearsData } = useYearsDataQuery();

  const carbonFieldValidationYears =
    yearsData?.yearFlowData.carbonFieldValidationYears ?? [];
  const latestCarbonFieldValidationYear =
    carbonFieldValidationYears[carbonFieldValidationYears.length - 1] ?? [];

  const { data: submittableFields, isLoading: isSubmittableFieldsLoading } =
    useFieldsQuery(
      {
        harvestYear,
        filters: {
          harvestYear,
          pendingValidation: true,
        },
      },
      {
        enabled: Boolean(latestCarbonFieldValidationYear),
        onSuccess: submittableFields => {
          if (!submittableFields) {
            return;
          }

          const fieldsToBeSubmitted: Record<string, boolean> = {};
          submittableFields.items.forEach(field => {
            fieldsToBeSubmitted[field.id] = true;
          });

          setFieldsToBeSubmitted(fieldsToBeSubmitted);
          onChosenFieldsUpdate(fieldsToBeSubmitted);
        },
      },
    );

  const handleFieldClicked = ({ fieldId, action }: HandleFieldClickedArgs) => {
    const isFieldRemoved =
      action === SubmitFieldsForValidationListAction.Remove;
    const newFieldsToBeSubmitted = {
      ...fieldsToBeSubmitted,
      [fieldId]: !isFieldRemoved,
    };

    setFieldsToBeSubmitted(newFieldsToBeSubmitted);
    onChosenFieldsUpdate(newFieldsToBeSubmitted);
  };

  const handleRemoveAllValidatedFieldsClicked = () => {
    if (!fieldsToBeSubmitted) {
      return;
    }

    const newFieldsBySubmission = Object.keys(fieldsToBeSubmitted).reduce(
      (acc: Record<number, boolean>, fieldId: string) => {
        acc[Number(fieldId)] = false;

        return acc;
      },
      {},
    );

    setFieldsToBeSubmitted(newFieldsBySubmission);
  };

  if (isSubmittableFieldsLoading) {
    return <FieldsForValidationSkeleton />;
  }

  if (!submittableFields || !fieldsToBeSubmitted) {
    return null;
  }

  return (
    <>
      <SFieldsForValidationModalListContent>
        <FieldsForValidationList
          submittableFields={submittableFields.items}
          fieldsToBeSubmitted={fieldsToBeSubmitted}
          onFieldClick={handleFieldClicked}
          onRemoveAllValidatedFieldsClick={
            handleRemoveAllValidatedFieldsClicked
          }
        />

        {children}
      </SFieldsForValidationModalListContent>
    </>
  );
};

export default FieldsForValidation;
