import axios from "axios";
import { useMutation } from "react-query";

import { ToastNotification } from "@ag/utils/services";

const resendConfirmationEmail = async (email: string) => {
  await axios.post(
    `/api/v1/auth/resend-confirmation`,
    { email },
    { apiSource: "node-auth" },
  );
};

export const useResendConfirmationEmailMutation = () =>
  useMutation(resendConfirmationEmail, {
    onError: error => {
      ToastNotification.error(error);
    },
  });
