import { useLocation, useNavigate } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { useDidUpdate } from "rooks";

import { Logo } from "@ag/design-system/assets";
import { Button } from "@ag/design-system/atoms";
import I18n from "@ag/i18n";

import ImgPest from "../assets/icon-pest.svg";
import { SContainer, SIcon } from "../styled";

type Props = {
  onErrorReset: () => void;
};

const GeneralError = ({ onErrorReset }: Props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const intercom = useIntercom();

  useDidUpdate(() => {
    onErrorReset();
  }, [pathname]);

  const handleMessageClicked = (event: React.MouseEvent) => {
    event.preventDefault();

    // Show intercom dialog if "contact support" link in the message has been clicked
    if ((event.target as HTMLElement).tagName.toLowerCase() === "a") {
      intercom.show();
    }
  };

  return (
    <SContainer>
      <Logo className="w-[132px]" data-testid="logo" />

      <SIcon src={ImgPest} />

      <h2 className="text-h2">{I18n.t("js.error_boundary.oops")}</h2>

      <p
        className="mb-4 text-center"
        dangerouslySetInnerHTML={{
          __html: I18n.t("js.error_boundary.unexpected_error"),
        }}
      />

      <Button className="mb-4" onClick={() => navigate(-1)}>
        {I18n.t("js.error_boundary.go_back")}
      </Button>

      <p
        className="mb-4 text-center"
        dangerouslySetInnerHTML={{
          __html: I18n.t("js.error_boundary.contact_support"),
        }}
        onClick={handleMessageClicked}
      />
    </SContainer>
  );
};

export default GeneralError;
