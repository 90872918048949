import pick from "lodash/pick";

import I18n from "@ag/i18n";

import { ModalContentProps } from "~components/GlobalModal";
import { CoreFieldData, useFieldQuery } from "~queries/carbon/fields";

import {
  CoreFieldDataAttribute,
  FieldDetailsFormMode,
} from "../components/FieldDetailsForm";
import EditFieldForm from "../components/FieldDetailsForm/EditFieldForm";

export type Extras = {
  fieldId: string;
  onSuccess?: () => void;
  formMode?: FieldDetailsFormMode;
};

const EditField = ({ extras, isOpen, onClose }: ModalContentProps) => {
  const { fieldId, onSuccess, formMode } = (extras as Extras) || {};

  const { data: field } = useFieldQuery(
    { fieldId },
    { enabled: Boolean(fieldId) },
  );

  if (!field) {
    return null;
  }

  const handleFieldEditSucceed = () => {
    const handler = onSuccess;

    if (handler) {
      handler();
    }

    onClose();
  };

  const initialData = pick(field, Object.values(CoreFieldDataAttribute));

  return (
    <EditFieldForm
      title={I18n.t("js.carbon.edit_field_details")}
      submitButtonText={I18n.t("js.carbon.save_field")}
      initialData={initialData as CoreFieldData}
      formMode={formMode || FieldDetailsFormMode.Edit}
      fieldId={field.id}
      isOpen={isOpen}
      onCancel={onClose}
      onSuccess={handleFieldEditSucceed}
    />
  );
};

export default EditField;
