import groupBy from "lodash/groupBy";
import isEmpty from "lodash/isEmpty";
import { useMemo } from "react";

import { Accordion } from "@ag/design-system/organisms";
import I18n from "@ag/i18n";

import {
  SAddress,
  SAddressLine,
  SAddressName,
  SFieldItem,
  SFields,
} from "~components/GlobalModal/modals/validation-flow/styled";
import { useFarmsQuery } from "~features/farm";
import { useFieldsQuery } from "~queries/carbon/fields";

import { SList } from "./styled";

const ShowValidatedFields = () => {
  const { data: farms } = useFarmsQuery({ limit: 100 });
  const { data: fields, isSuccess: isFieldsReady } = useFieldsQuery();
  const {
    data: fieldsUnderValidation,
    isSuccess: isFieldsUnderValidationReady,
  } = useFieldsQuery({
    filters: {
      underValidation: true,
    },
  });

  const fieldsByAddressId = useMemo(
    () => groupBy(fieldsUnderValidation?.items, field => field.carbonFarmId),
    [fieldsUnderValidation?.items],
  );

  if (
    !fields ||
    !isFieldsReady ||
    !isFieldsUnderValidationReady ||
    isEmpty(fieldsByAddressId)
  ) {
    return null;
  }

  return (
    <Accordion.Root>
      <Accordion.Item value="under-validation">
        <Accordion.Header>
          {I18n.t("js.carbon.show_fields_under_validation")}
        </Accordion.Header>

        <Accordion.Content>
          {Object.entries(fieldsByAddressId).map(([id, farmFields]) => {
            const farm = farms?.items?.find(farm => farm.id === id);

            return (
              <SList key={id}>
                <SAddress>
                  {farm && <SAddressName>{farm?.name}</SAddressName>}

                  <SAddressLine />
                </SAddress>

                <SFields>
                  {farmFields.map(field => (
                    <SFieldItem key={field.id} className="text-p1">
                      {field.name}
                    </SFieldItem>
                  ))}
                </SFields>
              </SList>
            );
          })}
        </Accordion.Content>
      </Accordion.Item>
    </Accordion.Root>
  );
};

export default ShowValidatedFields;
