import { useCallback, useState } from "react";

import { AppHistoryContext } from "./context";

const AppHistoryProvider = ({ children }: React.PropsWithChildrenRequired) => {
  const [historyStack, setHistoryStack] = useState<string[]>([]);

  // Stack elements getters
  const currentPage = historyStack[historyStack.length - 1];
  const previousPage = historyStack[historyStack.length - 2];

  // Stack modifiers
  const addPage = useCallback(
    (page: string) =>
      setHistoryStack(previousStack => [...previousStack, page]),
    [],
  );

  const removeFirstPage = useCallback(
    () => setHistoryStack(previousStack => previousStack.slice(1)),
    [],
  );

  const removeLastPage = useCallback(
    () => setHistoryStack(previousStack => previousStack.slice(0, -1)),
    [],
  );

  const updateLastPage = useCallback(
    (page: string) =>
      setHistoryStack(previousStack => {
        previousStack.pop();

        return [...previousStack, page];
      }),
    [],
  );

  const resetStack = useCallback(() => setHistoryStack([]), []);

  return (
    <AppHistoryContext.Provider
      value={{
        historyStack,

        currentPage,
        previousPage,

        addPage,
        removeFirstPage,
        removeLastPage,
        updateLastPage,
        resetStack,
      }}
    >
      {children}
    </AppHistoryContext.Provider>
  );
};

export default AppHistoryProvider;
