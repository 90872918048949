import axios from "axios";
import { useMutation } from "react-query";

import { ToastNotification } from "@ag/utils/services";

import { queryClient } from "~lib/react-query";
import { fieldsKeys } from "~queries/carbon/fields";

import { FieldDefinitionUpdateData } from "../schemas/update-field-definition";
import { fieldDefinitionsKeys } from "./get-field-definition";

/**
 * CUSTOM MUTATION FUNCTIONS
 */
export const updateFieldDefinition = async (
  fieldId: string,
  fieldDefinitionId: number,
  fieldDefinition: FieldDefinitionUpdateData,
) => {
  await axios.put<FieldDefinitionUpdateData>(
    `/api/v1/carbon-fields/${fieldId}/definitions/${fieldDefinitionId}`,
    fieldDefinition,
    {
      apiSource: "node-carbon",
    },
  );
};

/**
 * MUTATION HOOKS
 */
export const useFieldDefinitionUpdateMutation = (
  fieldId: string,
  fieldDefinitionId: number,
  options?: {
    onSuccess?: () => void;
  },
) =>
  useMutation(
    (data: FieldDefinitionUpdateData) =>
      updateFieldDefinition(fieldId, fieldDefinitionId, data),
    {
      onSuccess: updatedFieldDefinition => {
        queryClient.setQueryData(
          fieldDefinitionsKeys.fieldDefinition(fieldId),
          updatedFieldDefinition,
        );

        queryClient.invalidateQueries(fieldsKeys.field({ fieldId }), {
          exact: false,
        });

        if (options?.onSuccess) options?.onSuccess();
      },
      onError: error => {
        ToastNotification.error(error);
      },
    },
  );
