import * as Sentry from "@sentry/react";
import { Integrations as TracingIntegrations } from "@sentry/tracing";
import { useEffect } from "react";
import {
  Routes,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

import { env } from "~config";
import { CurrentUser } from "~features/authentication";

import packageJson from "../../package.json";

export const setupSentry = () => {
  if (!env.REACT_APP_SENTRY_DSN) return;

  Sentry.init({
    dsn: env.REACT_APP_SENTRY_DSN,

    /*
     * Don't report chunk loading errors as there is a custom error page for them
     * Google campaign redirecting to 404 URLs
     */
    ignoreErrors: [
      "ChunkLoadError",
      /^Route not found: \/https:\/www.agreena.com/,
    ],

    // Integrations
    integrations: [
      new TracingIntegrations.BrowserTracing({
        tracePropagationTargets: ["localhost", "agreena.com"],
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
      new Sentry.Replay(),
    ],

    // Tracing
    tracesSampleRate: 1,

    // Replays
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,

    // Release
    release:
      env.REACT_APP_APP_ENVIRONMENT === "production"
        ? packageJson.version
        : undefined,

    // Misc
    attachStacktrace: true,
    environment: env.REACT_APP_APP_ENVIRONMENT,
  });
};

export const addCurrentUserToSentry = (user: CurrentUser) => {
  Sentry.configureScope(scope => {
    scope.setUser({
      ...user,
      id: String(user.id),
    });
  });
};

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
