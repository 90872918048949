import { merge } from "lodash";

import { DEFAULT_LOCALE } from "../constants";
import defaultSharedTranslations from "../shared/en.json";
import { Locale, LocaleFile, TranslationsLoader } from "../types";
import defaultAppTranslations from "./en.json";

const lazyAppTranslations: Record<Locale, () => Promise<LocaleFile> | null> = {
  en: () => Promise.resolve({ default: defaultAppTranslations }),
  da: () => import("./da.json"),
  ro: () => import("./ro.json"),
  lt: () => import("./lt.json"),
  pl: () => import("./pl.json"),
  sk: () => import("./sk.json"),
  fr: () => import("./fr.json"),
  de: () => import("./de.json"),
  es: () => import("./es.json"),
  uk: () => import("./uk.json"),
  et: () => import("./et.json"),
  bg: () => import("./bg.json"),
  cs: () => import("./cs.json"),
  hu: () => import("./hu.json"),
  fi: () => import("./fi.json"),
  lv: () => import("./lv.json"),
  sv: () => import("./sv.json"),
  kk: () => import("./kk.json"),
};

const lazySharedTranslations: Record<Locale, () => Promise<LocaleFile>> = {
  en: () => Promise.resolve({ default: defaultSharedTranslations }),
  da: () => import("../shared/da.json"),
  ro: () => import("../shared/ro.json"),
  lt: () => import("../shared/lt.json"),
  pl: () => import("../shared/pl.json"),
  sk: () => import("../shared/sk.json"),
  fr: () => import("../shared/fr.json"),
  de: () => import("../shared/de.json"),
  es: () => import("../shared/es.json"),
  uk: () => import("../shared/uk.json"),
  et: () => import("../shared/et.json"),
  bg: () => import("../shared/bg.json"),
  cs: () => import("../shared/cs.json"),
  hu: () => import("../shared/hu.json"),
  fi: () => import("../shared/fi.json"),
  lv: () => import("../shared/lv.json"),
  sv: () => import("../shared/sv.json"),
  kk: () => import("../shared/kk.json"),
};

export const loadTranslations: TranslationsLoader = async (
  locale = DEFAULT_LOCALE,
) => {
  const [appTranslations, sharedTranslations] = await Promise.all([
    lazyAppTranslations[locale](),
    lazySharedTranslations[locale](),
  ]);

  return merge(
    appTranslations?.default ?? {},
    sharedTranslations?.default ?? {},
  );
};
