import isEmpty from "lodash/isEmpty";
import { useState } from "react";

import { Button } from "@ag/design-system/atoms";
import I18n from "@ag/i18n";
import { ToastNotification } from "@ag/utils/services";

import FieldsForValidation from "~components/GlobalModal/modals/validation-flow/components/FieldsForValidation";
import {
  SAddFieldsContent,
  SDescription,
  SStepFooter,
} from "~components/GlobalModal/modals/validation-flow/styled";
import { queryClient } from "~lib/react-query";
import { fieldsKeys, useValidateFieldMutation } from "~queries/carbon/fields";
import {
  ValidationRequestStatus,
  useValidationRequestsQuery,
} from "~queries/carbon/validation-requests";

type Props = {
  harvestYear: number;
  onClose: () => void;
};

const FieldValidationManagement = ({ harvestYear, onClose }: Props) => {
  const [carbonFieldIds, setCarbonFieldIds] = useState<string[] | null>(null);

  const { data: validationRequests } = useValidationRequestsQuery();

  const validateFieldMutation = useValidateFieldMutation();

  const handleSubmitButtonClicked = () => {
    if (isEmpty(carbonFieldIds)) {
      ToastNotification.warning(
        I18n.t("js.carbon.please_choose_fields_to_validate"),
      );
      return;
    }

    const pendingValidationRequest = validationRequests?.find(
      validationRequest =>
        validationRequest.status === ValidationRequestStatus.New,
    );

    if (!pendingValidationRequest) return;

    const { eventUri, inviteeUri } = pendingValidationRequest;

    if (carbonFieldIds && eventUri && inviteeUri && harvestYear) {
      const params = {
        carbonFieldIds: carbonFieldIds.map(String),
        scheduledEvent: {
          eventUri: pendingValidationRequest.eventUri,
          inviteeUri: pendingValidationRequest.inviteeUri,
        },
        harvestYear,
      };

      validateFieldMutation.mutate(params, {
        onSuccess: () => {
          queryClient.invalidateQueries(fieldsKeys.fieldsAll());

          onClose();

          ToastNotification.success(
            I18n.t("js.carbon.validation_application_has_been_created", {
              harvestYear,
            }),
          );
        },
      });
    }
  };

  return (
    <>
      <SDescription
        className="text-p2"
        dangerouslySetInnerHTML={{
          __html: I18n.t("js.carbon.validation_modal_choose_field_intro"),
        }}
      />

      <SAddFieldsContent>
        <FieldsForValidation
          harvestYear={harvestYear}
          onChosenFieldsUpdate={fieldsToBeSubmitted => {
            const fieldIdsAddedToValidate = Object.keys(
              fieldsToBeSubmitted,
            ).filter((key: string) => fieldsToBeSubmitted[Number(key)]);

            setCarbonFieldIds(fieldIdsAddedToValidate);
          }}
        />
      </SAddFieldsContent>

      <SStepFooter>
        <Button variant="text" onClick={onClose}>
          {I18n.t("js.shared.cancel")}
        </Button>

        <Button onClick={handleSubmitButtonClicked}>
          {I18n.t("js.shared.submit")}
        </Button>
      </SStepFooter>
    </>
  );
};

export default FieldValidationManagement;
