import styled from "@emotion/styled";
import { ToastContainer } from "react-toastify";

import { colorsObject } from "@ag/design-system/tokens";

// TODO: Rewrite to .css.ts
export const StyledToastContainer = styled(ToastContainer)`
  font-size: 14px; // set here as it's 3rd party library

  // Success notification
  .Toastify__toast--success svg {
    fill: ${colorsObject.accent[200]};
  }

  .Toastify__progress-bar--success {
    background-color: ${colorsObject.accent[200]};
  }

  // Error notification
  .Toastify__toast--error svg {
    fill: ${colorsObject.messaging.error[700]};
  }

  .Toastify__progress-bar--error {
    background-color: ${colorsObject.messaging.error[700]};
  }

  // Info notification
  .Toastify__toast--info svg {
    fill: ${colorsObject.messaging.info[700]};
  }

  .Toastify__progress-bar--info {
    background-color: ${colorsObject.messaging.info[700]};
  }
`;
