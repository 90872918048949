import axios from "axios";
import { useMutation } from "react-query";

import { ToastNotification } from "@ag/utils/services";

export type UpdateCurrentUserData = Partial<{
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  acceptedNewsletter: boolean;
  preferredCurrency: string;
  startupPage: string;
  locale: string;
  roles: string[];
}>;

const updateCurrentUser = async (data: UpdateCurrentUserData) => {
  await axios.put(`/api/v1/users/me`, data, {
    apiSource: "node-auth",
  });
};

export const useUpdateCurrentUserMutation = () =>
  useMutation(updateCurrentUser, {
    onError: error => {
      ToastNotification.error(error);
    },
  });
