import { Logo } from "@ag/design-system/assets";
import { Button } from "@ag/design-system/atoms";

import { useSessionContext } from "~features/authentication";

const Unsupported = () => {
  const { signOut } = useSessionContext();

  return (
    <section className="grid h-screen place-items-center">
      <div className="flex flex-col items-center gap-8 text-center">
        <Logo className="h-10" />

        <div className="flex flex-col gap-4">
          <h1 className="text-h2">This feature is no longer supported. </h1>
          <p className="text-p1">
            Please contact our Support team or your dedicated Account Manager
            <br />
            to access the replacement functionality.
          </p>

          <Button variant="text" onClick={() => signOut()}>
            Sign out
          </Button>
        </div>
      </div>
    </section>
  );
};

export default Unsupported;
