import { useState } from "react";

import { Button } from "@ag/design-system/atoms";
import I18n from "@ag/i18n";

import LearnMoreModal from "./LearnMoreModal";
import { SLearnMoreButtonContainer, SRoot } from "./styled";

type Props = {
  description: string;
  modalText: string;
};

const LearnMore = ({ description, modalText }: Props) => {
  const [isLearnMoreModalOpen, setIsLearnMoreModalOpen] = useState(false);

  return (
    <>
      <SRoot>
        <p
          className="mb-2 mt-4 text-center"
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />

        <SLearnMoreButtonContainer>
          <Button variant="text" onClick={() => setIsLearnMoreModalOpen(true)}>
            {I18n.t("js.carbon.learn_more")}
          </Button>
        </SLearnMoreButtonContainer>
      </SRoot>

      <LearnMoreModal
        text={modalText}
        isModalOpen={isLearnMoreModalOpen}
        onModalClose={() => setIsLearnMoreModalOpen(false)}
      />
    </>
  );
};

export default LearnMore;
