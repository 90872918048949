import axios from "axios";
import { useMutation } from "react-query";

import { ToastNotification } from "@ag/utils/services";

type ResetPasswordArgs = {
  password: string;
  passwordConfirmation: string;
};

const resetPassword = async (
  resetPasswordToken: string,
  data: ResetPasswordArgs,
) => {
  await axios.patch(
    `/api/v1/users/${resetPasswordToken}/reset-password`,
    data,
    {
      apiSource: "node-auth",
    },
  );
};

export const useResetPasswordMutation = (resetPasswordToken: string) =>
  useMutation(
    (data: ResetPasswordArgs) => resetPassword(resetPasswordToken, data),
    {
      onError: error => {
        ToastNotification.error(error);
      },
    },
  );
