import { useAtom } from "jotai";
import { Controller, useFormContext } from "react-hook-form";

import { ButtonSelect } from "@ag/design-system/molecules";
import { ButtonSelectField } from "@ag/form-fields";
import I18n from "@ag/i18n";

import FormSection from "~components/form-section";

import { isDisabledAtom } from "../atoms/is-disabled-atom";
import { type FieldDefinitionData } from "../schemas/field-definition-form";

const NitrificationInhibitorsFormSection = () => {
  const [isDisabled] = useAtom(isDisabledAtom);

  const { control, formState } = useFormContext<FieldDefinitionData>();

  const errors = formState.errors.questionnaire;
  return (
    <FormSection.Root
      title={I18n.t(
        "js.carbon.field_details.field_definition.questionnaire.nitrification.title",
      )}
    >
      <FormSection.Card>
        <Controller
          name="questionnaire.nitrificationInhibitorsConsistentlyTheLastFiveYears"
          control={control}
          render={({ field }) => (
            <ButtonSelectField
              {...field}
              label={I18n.t(
                "js.carbon.field_details.field_definition.questionnaire.consitently_nitrifcation_inhibitors.title",
              )}
              tooltip={I18n.t(
                "js.carbon.field_details.field_definition.questionnaire.consitently_nitrifcation_inhibitors.tooltip",
              )}
              testId="nitrification-inhibitors"
              error={
                errors?.nitrificationInhibitorsConsistentlyTheLastFiveYears
              }
              isDisabled={isDisabled}
              isRequired
            >
              <ButtonSelect.Option value={true}>
                {I18n.t("js.shared.yes")}
              </ButtonSelect.Option>

              <ButtonSelect.Option value={false}>
                {I18n.t("js.shared.no")}
              </ButtonSelect.Option>
            </ButtonSelectField>
          )}
        />
      </FormSection.Card>

      <FormSection.Card>
        <Controller
          name="questionnaire.cropProtectionInAnyOfTheLastFiveYears"
          control={control}
          render={({ field }) => (
            <ButtonSelectField
              {...field}
              label={I18n.t(
                "js.carbon.field_details.field_definition.questionnaire.crop_protection.title",
              )}
              tooltip={I18n.t(
                "js.carbon.field_details.field_definition.questionnaire.crop_protection.tooltip",
              )}
              testId="crop-protection-in-last-five-years"
              error={errors?.cropProtectionInAnyOfTheLastFiveYears}
              isDisabled={isDisabled}
              isRequired
            >
              <ButtonSelect.Option value={true}>
                {I18n.t("js.shared.yes")}
              </ButtonSelect.Option>

              <ButtonSelect.Option value={false}>
                {I18n.t("js.shared.no")}
              </ButtonSelect.Option>
            </ButtonSelectField>
          )}
        />
      </FormSection.Card>
    </FormSection.Root>
  );
};

export default NitrificationInhibitorsFormSection;
