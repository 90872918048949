import { FormApi, FormState, ValidationErrors } from "final-form";
import { useRef, useState } from "react";
import { Field, FormSpy, withTypes } from "react-final-form";

import { Button, InfoBox } from "@ag/design-system/atoms";
import { Select } from "@ag/design-system/molecules";
import { Modal } from "@ag/design-system/organisms";
import { InputField, SelectField } from "@ag/form-fields";
import I18n from "@ag/i18n";
import { ToastNotification } from "@ag/utils/services";

import {
  mapDeliveryAddresses,
  validate,
} from "~components/GlobalModal/modals/field/components/FieldDetailsForm/helpers";
import {
  CoreFieldDataAttribute,
  FieldDetailsFormMode,
} from "~components/GlobalModal/modals/field/components/FieldDetailsForm/types";
import { useFarmsQuery } from "~features/farm";
import { queryClient } from "~lib/react-query";
import {
  CoreFieldData,
  fieldsKeys,
  useUpdateFieldMutation,
} from "~queries/carbon/fields";

const { Form: FinalForm } = withTypes<CoreFieldData>();

type Props = {
  title: string;
  submitButtonText: string;
  fieldId: string;
  formMode?: FieldDetailsFormMode;
  initialData?: Partial<CoreFieldData>;
  isOpen: boolean;
  onCancel: () => void;
  onSuccess: () => void;
};

const EditFieldForm = ({
  title,
  fieldId,
  initialData,
  submitButtonText,
  formMode,
  isOpen,
  onCancel,
  onSuccess,
}: Props) => {
  const { data: farms, isLoading: isFarmsLoading } = useFarmsQuery({
    limit: 100,
  });

  const formRef = useRef<FormApi<CoreFieldData> | null>(null);
  const farmIdRef = useRef<string | null>(null);

  const [isCountryChanged, setIsCountryChanged] = useState(false);

  const updateFieldMutation = useUpdateFieldMutation();

  const isQuickMode = formMode === FieldDetailsFormMode.QuickEdit;

  const getIsCountryChanged = (farmId: string): boolean => {
    if (!initialData) {
      return false;
    }

    const initialFarmId = initialData.carbonFarmId;

    const initialFarm = farms?.items.find(farm => farm.id === initialFarmId);
    const currentFarm = farms?.items.find(farm => farm.id === farmId);

    const initialCountryCode = initialFarm?.carbonCountry.code;
    const currentCountryCode = currentFarm?.carbonCountry.code;

    return initialCountryCode !== currentCountryCode;
  };

  const handleFarmChanged = (farmId: string): void => {
    const isDifferentCountry = getIsCountryChanged(farmId);

    setIsCountryChanged(isDifferentCountry);

    if (!formRef.current) {
      return;
    }
  };

  const handleFormSubmitted = (data: CoreFieldData) => {
    updateFieldMutation.mutate(
      {
        fieldId,
        field: {
          ...data,
          sizeHa: Number(data.sizeHa),
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(fieldsKeys.field({ fieldId }));

          ToastNotification.success(
            I18n.t("js.carbon.field_details.field_updated_success"),
          );

          onSuccess();
        },
      },
    );
  };

  return (
    /**
     * TODO: To be removed after ct-button is styled in styled-components
     * https://app.asana.com/0/1120148049704812/1199619057156647
     */
    <Modal.Root
      data-testid="addFieldModal"
      isOpen={isOpen}
      onRequestClose={onCancel}
    >
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Content>
        {isCountryChanged && (
          <InfoBox variant="warning" className="mb-4">
            {I18n.t("js.carbon.reset_data_warning")}
          </InfoBox>
        )}

        <FinalForm
          initialValues={initialData}
          onSubmit={handleFormSubmitted}
          validate={(
            values,
          ): ValidationErrors | Promise<ValidationErrors> | undefined =>
            validate({ values })
          }
          render={({ form, handleSubmit }): React.ReactNode => {
            formRef.current = form;

            return (
              <>
                <FormSpy
                  subscription={{ values: true }}
                  onChange={({ values }: FormState<CoreFieldData>): void => {
                    if (farmIdRef.current !== values.carbonFarmId) {
                      handleFarmChanged(values.carbonFarmId);
                    }

                    farmIdRef.current = values.carbonFarmId;
                  }}
                />

                <Field
                  name={CoreFieldDataAttribute.Name}
                  testTag="fieldDetailsForm-nameInput"
                  render={({ input, meta }) => (
                    <InputField
                      {...input}
                      label={I18n.t("js.carbon.field_name_or_identifier")}
                      error={
                        meta.touched && meta.error
                          ? { message: meta.error }
                          : undefined
                      }
                    />
                  )}
                />

                <Field
                  name={CoreFieldDataAttribute.SizeHa}
                  placeholder={I18n.t("js.carbon.size_of_field_in_ha")}
                  testTag="fieldDetailsForm-sizeHaInput"
                  type="number"
                  render={({ input, meta }) => (
                    <InputField
                      {...input}
                      label={I18n.t("js.carbon.field_size_in_ha")}
                      error={
                        meta.touched && meta.error
                          ? { message: meta.error }
                          : undefined
                      }
                    />
                  )}
                />

                {(!formMode || !isQuickMode) && (
                  <Field
                    loading={isFarmsLoading || farms?.items?.length === 0}
                    name={CoreFieldDataAttribute.CarbonFarmId}
                    render={({ input, meta }) => (
                      <SelectField
                        {...input}
                        label={I18n.t("js.carbon.nearest_address")}
                        optionsClassName="z-modal"
                        error={
                          meta.touched && meta.error
                            ? { message: meta.error }
                            : undefined
                        }
                      >
                        {mapDeliveryAddresses(farms?.items).map(
                          ({ label, value }) => (
                            <Select.Option key={value} value={value}>
                              {label}
                            </Select.Option>
                          ),
                        )}
                      </SelectField>
                    )}
                    testTag="fieldDetails-addressInput"
                  />
                )}

                <div className="mt-4 flex items-center justify-end gap-10">
                  <Button
                    data-testid="fieldDetails-cancelButton"
                    variant="text"
                    onClick={onCancel}
                  >
                    {I18n.t("js.shared.cancel")}
                  </Button>

                  <Button
                    data-testid="fieldDetailsForm-submitButton"
                    type="submit"
                    isLoading={updateFieldMutation.isLoading}
                    onClick={handleSubmit}
                  >
                    {submitButtonText}
                  </Button>
                </div>
              </>
            );
          }}
        />
      </Modal.Content>
    </Modal.Root>
  );
};

export default EditFieldForm;
