import axios from "axios";
import { useMutation } from "react-query";

import { ToastNotification } from "@ag/utils/services";

import { ValidationRequestUpdateArgs } from "./types";

/**
 * CUSTOM MUTATION FUNCTIONS
 */
const updateValidationRequest = async (args: ValidationRequestUpdateArgs) => {
  const { requestId, carbonFieldIds } = args;

  await axios.put(
    `/api/v1/carbon-validation-requests/${requestId}`,
    {
      carbonFieldIds,
    },
    {
      apiSource: "node-carbon",
    },
  );
};

/**
 * MUTATION HOOKS
 */
export const useUpdateValidationRequestMutation = () =>
  useMutation(updateValidationRequest, {
    onError: error => {
      ToastNotification.error(error);
    },
  });
