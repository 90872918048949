import { z } from "zod";

import { CarbonHarvestYearsStatsSchema, CarbonStatsSchema } from "./schemas";

/**
 * ENTITIES
 */
export type CarbonHarvestYearsStats = z.infer<
  typeof CarbonHarvestYearsStatsSchema
>;

export type CarbonStats = z.infer<typeof CarbonStatsSchema>;
/**
 * QUERY KEY ARGS
 */

/**
 * QUERY ARGS
 */

/**
 * QUERY FUNCTION ARGS
 */

/**
 * MUTATION FUNCTION ARGS
 */

/**
 *  QUERY SELECT ARGS
 */

/**
 * MISC
 */

// DON'T MODIFY - aligned with the API keys
export enum FieldStatus {
  Validated = "validated",
  NonValidated = "nonValidated",
}
