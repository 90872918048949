import { z } from "zod";

export const CarbonCountrySchema = z.object({
  id: z.string(),
  code: z.string(),
  name: z.string(),
  isActiveMarket: z.boolean(),
});

export type CarbonCountry = z.infer<typeof CarbonCountrySchema>;
