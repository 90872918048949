import { Spinner } from "@ag/design-system/assets";

import { SFullPageSpinner } from "./styled";

const FullPageSpinner = () => (
  <SFullPageSpinner>
    <Spinner className="w-10 text-satellite-200" />
  </SFullPageSpinner>
);

export default FullPageSpinner;
