import axios from "axios";

import { AuthData, AuthDataSchema } from "@ag/utils/schemas";
import { CommonResponse } from "@ag/utils/types";

export const signInAs = async (signInAsTicket: string) => {
  const response = await axios.post<CommonResponse<AuthData>>(
    "/api/v1/auth/exchange-ticket",
    { ticket: signInAsTicket },
    {
      schema: AuthDataSchema,
      apiSource: "node-auth",
    },
  );

  return response.data.success!.data;
};
