import styled from "@emotion/styled";

const size = {
  mapHeight: 500,
};

export const SFieldMap = styled.div`
  .field-map {
    width: 100%;
    height: ${size.mapHeight}px;
  }
`;
