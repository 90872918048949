import styled from "@emotion/styled";

import { colorsObject } from "@ag/design-system/tokens";

type SListTitleProps = {
  isSkeleton?: boolean;
};

export const SListTitleWrapper = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;

  background-color: ${colorsObject.grey.white};
`;

export const SListTitle = styled.div`
  background-color: ${colorsObject.grey[200]};
  line-height: 24px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 8px 16px;

  margin-top: ${({ isSkeleton }: SListTitleProps) => (isSkeleton ? "16px" : 0)};
`;

export const SMarkFieldIcon = styled.img`
  margin-left: 12px;
  width: 24px;
  cursor: pointer;
`;

export const SFieldsForValidationModalListContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const SAdditionalActions = styled.div`
  width: 100%;
  text-align: right;
  line-height: 24px;
`;
