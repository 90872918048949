import { DeepPartial } from "react-hook-form";

import { FieldDefinition } from "../schemas/field-definition";
import { FieldDefinitionData } from "../schemas/field-definition-form";

// API may return null but form field should be undefined
const convertApiPracticeValuesToForm = (fieldPractice: any) => {
  if (fieldPractice?.totalYield === null) {
    fieldPractice.totalYield = undefined;
  }
  return fieldPractice;
};

export const getFormDefaultValues = ({
  soilDrainage,
  soilMgmtHistoricConversion,
  questionnaire,
  verraQuestionnaire,
}: FieldDefinition): DeepPartial<FieldDefinitionData> => {
  return {
    soilDrainage,
    soilMgmtHistoricConversion,
    questionnaire: {
      nitrificationInhibitorsConsistentlyTheLastFiveYears:
        questionnaire.nitrificationInhibitorsConsistentlyTheLastFiveYears,
      cropProtectionInAnyOfTheLastFiveYears:
        questionnaire.cropProtectionInAnyOfTheLastFiveYears,
    },
    verraQuestionnaire: {
      fieldPracticesOneYearAgo: convertApiPracticeValuesToForm(
        verraQuestionnaire?.fieldPracticesOneYearAgo,
      ),
      fieldPracticesTwoYearsAgo: convertApiPracticeValuesToForm(
        verraQuestionnaire?.fieldPracticesTwoYearsAgo,
      ),
      fieldPracticesThreeYearsAgo: convertApiPracticeValuesToForm(
        verraQuestionnaire?.fieldPracticesThreeYearsAgo,
      ),
      fieldPracticesFourYearsAgo: convertApiPracticeValuesToForm(
        verraQuestionnaire?.fieldPracticesFourYearsAgo,
      ),
      fieldPracticesFiveYearsAgo: convertApiPracticeValuesToForm(
        verraQuestionnaire?.fieldPracticesFiveYearsAgo,
      ),
      ...verraQuestionnaire,
    },
  };
};
