import axios from "axios";
import { UseQueryOptions, useQuery } from "react-query";

import { CommonResponse } from "@ag/utils/types";

import { Farm, FarmSchema } from "../entities/farm";

type UseFarmQueryOptions = Pick<UseQueryOptions, "enabled"> & {
  onSuccess?: (data: Farm) => void;
};

export const generateFarmQueryKey = (farmId: string) => ["farm", farmId];

const getFarm = async (farmId: string) => {
  const response = await axios.get<CommonResponse<Farm>>(
    `/api/v1/farms/${farmId}`,
    {
      apiSource: "node-carbon",
      schema: FarmSchema,
    },
  );

  return response.data.success!.data;
};

export const useFarmQuery = (farmId: string, options?: UseFarmQueryOptions) =>
  useQuery(generateFarmQueryKey(farmId), () => getFarm(farmId), {
    enabled: Boolean(farmId),
    ...options,
  });
