import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";

import { Button } from "@ag/design-system/atoms";
import { Modal } from "@ag/design-system/organisms";
import I18n from "@ag/i18n";
import { ToastNotification } from "@ag/utils/services";

import { useSessionContext } from "~features/authentication";
import { useUpdateFarmMutation } from "~features/farm/api/update-farm";

import { useFarmQuery } from "../api/get-farm";
import FarmForm, {
  FarmFormValues,
  farmFormSchema,
} from "./farm-form/farm-form";

type Props = {
  farmId: string;
  isOpen: boolean;
  onClose: () => void;
};

const EditFarmModal = ({ farmId, isOpen, onClose }: Props) => {
  const { currentUser } = useSessionContext();

  const updateFarmMutation = useUpdateFarmMutation(farmId);
  const { data: farm } = useFarmQuery(farmId);

  const {
    control,
    register,
    reset,
    formState: { errors },
    handleSubmit: handleFormSubmit,
  } = useForm<FarmFormValues>({
    resolver: zodResolver(
      farmFormSchema(currentUser?.company?.address.countryCode),
    ),
  });

  useEffect(() => {
    if (farm) {
      reset({ name: farm.name, countryCode: farm.carbonCountry.code });
    }
  }, [farm, reset]);

  const handleEditFarm = useCallback(
    (data: FarmFormValues) => {
      updateFarmMutation.mutate(data, {
        onSuccess: () => {
          ToastNotification.success(
            I18n.t(
              "js.farmer.carbon.farmer_dashboard.edit_farm_modal.success_notification",
            ),
          );
          onClose();
        },
      });
    },
    [updateFarmMutation, onClose],
  );

  if (!farm) {
    return null;
  }

  return (
    <Modal.Root isOpen={isOpen} onRequestClose={onClose}>
      <Modal.Header>
        <Modal.Title>
          {I18n.t("js.farmer.farm_form.edit_farm.title")}
        </Modal.Title>
      </Modal.Header>

      <Modal.Content>
        <FarmForm errors={errors} register={register} control={control} />
      </Modal.Content>

      <Modal.Footer
        render={() => (
          <>
            <Button variant="secondary" onClick={onClose}>
              {I18n.t("js.shared.cancel")}
            </Button>

            <Button onClick={handleFormSubmit(handleEditFarm)}>
              {I18n.t("js.shared.update")}
            </Button>
          </>
        )}
      />
    </Modal.Root>
  );
};

export default EditFarmModal;
