import { useMemo } from "react";
import { useFormContext } from "react-hook-form";

import { Icon, Spinner } from "@ag/design-system/assets";
import { Tabs } from "@ag/design-system/organisms";
import { box } from "@ag/design-system/utils";

import { useSessionContext } from "~features/authentication";

import { useVerraCropTypesQuery } from "../api/get-crop-types";
import { useVerraFertilisersQuery } from "../api/get-fertilisers";
import { FieldDefinitionData } from "../schemas/field-definition-form";
import { CropFormSection, YearKey } from "./crop-form-section";

const yearKeys: YearKey[] = [
  YearKey.ONE_YEAR,
  YearKey.TWO_YEARS,
  YearKey.THREE_YEARS,
  YearKey.FOUR_YEARS,
  YearKey.FIVE_YEARS,
];

type Props = {
  harvestYear: number;
};

const PreviousYearsFormSection = ({ harvestYear }: Props) => {
  const { currentUser } = useSessionContext();
  const { formState } = useFormContext<FieldDefinitionData>();

  const previousHarvestYear = harvestYear - 1;

  const years = useMemo(
    () =>
      Array.from({ length: 5 }, (_, i) => (previousHarvestYear - i).toString()),
    [previousHarvestYear],
  );

  const verraCrops = useVerraCropTypesQuery(currentUser!.locale);

  const verraFertilisers = useVerraFertilisersQuery(currentUser!.locale);

  const errors = formState.errors.verraQuestionnaire;

  return (
    <div style={{ marginTop: "32px" }}>
      {!verraCrops.data || !verraFertilisers.data ? (
        <Spinner />
      ) : (
        <Tabs.Root defaultValue={years[0]}>
          <Tabs.List>
            {years.map((year, index) => (
              <Tabs.Trigger
                key={`year-${index}`}
                value={year}
                testId={`year-${index}-tab`}
              >
                <Tabs.TriggerTitle>
                  <div className={box({ gap: 4 })}>
                    <span>{year}</span>
                    {errors && errors[yearKeys[index]] ? (
                      <Icon
                        name="circle-exclamation"
                        style={{ color: "#DB4851" }}
                      />
                    ) : null}
                  </div>
                </Tabs.TriggerTitle>
              </Tabs.Trigger>
            ))}
          </Tabs.List>

          {years.map((year, index) => (
            <Tabs.Content key={`year-content-${index}`} value={year}>
              <CropFormSection
                yearKey={yearKeys[index]}
                crops={verraCrops.data}
              />
            </Tabs.Content>
          ))}
        </Tabs.Root>
      )}
    </div>
  );
};

export default PreviousYearsFormSection;
