import { SListTitle } from "~components/GlobalModal/modals/validation-flow/components/FieldsForValidation/styled";
import {
  SAddresses,
  SFieldItem,
  SFields,
} from "~components/GlobalModal/modals/validation-flow/styled";
import Skeleton from "~components/Skeleton";

const FieldsForValidationSkeleton = () => {
  return (
    <SAddresses>
      <SListTitle isSkeleton>
        <Skeleton randomWidth />
      </SListTitle>

      <SFields>
        <SFieldItem dot={false} isSkeleton>
          <Skeleton randomWidth />
        </SFieldItem>

        <SFieldItem dot={false} isSkeleton>
          <Skeleton randomWidth />
        </SFieldItem>
      </SFields>

      <SListTitle isSkeleton>
        <Skeleton randomWidth />
      </SListTitle>

      <SFields>
        <SFieldItem dot={false} isSkeleton>
          <Skeleton randomWidth />
        </SFieldItem>

        <SFieldItem dot={false} isSkeleton>
          <Skeleton randomWidth />
        </SFieldItem>
      </SFields>
    </SAddresses>
  );
};

export default FieldsForValidationSkeleton;
