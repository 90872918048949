export enum CoreFieldDataAttribute {
  Name = "name",
  SizeHa = "sizeHa",
  CarbonFarmId = "carbonFarmId",
}

export enum FieldDetailsFormMode {
  QuickEdit = "QUICK_EDIT",
  Edit = "EDIT",
}
