import { formatDuration } from "date-fns";
import { useEffect, useState } from "react";
import { useIntervalWhen } from "rooks";

import { Logo } from "@ag/design-system/assets";
import I18n from "@ag/i18n";

import ImgRocket from "../assets/icon-pest.svg";
import { SContainer, SIcon, SSeconds } from "../styled";

const ChunkLoadError = () => {
  const [secondsToRefresh, setSecondsToRefresh] = useState(10);

  useEffect(() => {
    if (secondsToRefresh === 1) {
      window.location.reload();
    }
  }, [secondsToRefresh]);

  // Show error page for 10 seconds and reload the page
  useIntervalWhen(
    () => {
      setSecondsToRefresh(prevState => prevState - 1);
    },
    1000, // run every second
    secondsToRefresh > 1, // run only if the number of seconds is greater than 1
  );

  return (
    <SContainer>
      <Logo className="w-[132px]" data-testid="logo" />

      <SIcon src={ImgRocket} />

      <h2 className="text-h2">
        {I18n.t("js.error_boundary.we_are_getting_better")}
      </h2>

      <p className="mb-4 text-center">
        {I18n.t("js.error_boundary.refresh_the_page")}

        <SSeconds>
          {formatDuration({
            seconds: secondsToRefresh,
          })}
          ...
        </SSeconds>
      </p>

      <p className="mb-4 text-center">
        {I18n.t("js.error_boundary.thank_you_for_patience")}
      </p>
    </SContainer>
  );
};

export default ChunkLoadError;
