import axios from "axios";
import { useMutation } from "react-query";

import { Locale } from "@ag/i18n";
import { ToastNotification } from "@ag/utils/services";
import { CommonResponse } from "@ag/utils/types";

import { queryClient } from "~lib/react-query";

import { Contract } from "../entities/contract";
import { ContractType, CreditingPeriod } from "../types/contract";
import { generateContractsQueryKey } from "./get-contracts";

type CreateContractData = {
  contractType: ContractType;
  creditingPeriod: CreditingPeriod;
  fieldIds: string[];
  locale: Locale;
  year: number;
};

const createContract = async (contract: CreateContractData) => {
  const response = await axios.post<CommonResponse<Contract>>(
    "/api/v1/contracts",
    contract,
    { apiSource: "node-carbon" },
  );

  return response.data.success!.data;
};

export const useCreateContractMutation = () =>
  useMutation(createContract, {
    onSuccess: () => {
      queryClient.invalidateQueries(generateContractsQueryKey());
    },
    onError: error => {
      ToastNotification.error(error);
    },
  });
