import { useRef } from "react";

/**
 * Hook allowing to control the scrolling availability on the page
 */
export const useBlockScroll = (): [() => void, () => void] => {
  const isScrollBlocked = useRef<boolean>();
  const { documentElement: html, body } = document;

  const blockScroll = () => {
    if (isScrollBlocked.current) return;

    html.style.position = "relative";
    html.style.overflow = "hidden";
    body.style.position = "relative";
    body.style.overflow = "hidden";

    isScrollBlocked.current = true;
  };

  const unblockScroll = () => {
    if (!isScrollBlocked.current) return;

    html.style.position = "";
    html.style.overflow = "";
    body.style.position = "";
    body.style.overflow = "";

    isScrollBlocked.current = false;
  };

  return [blockScroll, unblockScroll];
};
