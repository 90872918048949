import Div100vh from "react-div-100vh";

import { EmptyState } from "@ag/design-system/organisms";
import { box } from "@ag/design-system/utils";

const MaintenanceMode = () => (
  <Div100vh className={box({ justify: "center", align: "center" })}>
    <EmptyState.Root>
      <EmptyState.Title>Under maintenance</EmptyState.Title>

      <div style={{ maxWidth: "470px", textAlign: "center" }}>
        <EmptyState.Description>
          Due to some scheduled maintenance work, our app is currently down. We
          are working to bring it back to you as soon as possible.
        </EmptyState.Description>

        <EmptyState.Description>
          If you wish to contact us in the meantime, please email us at{" "}
          <a href="mailto:support@agreena.com">support@agreena.com</a>
        </EmptyState.Description>
      </div>
    </EmptyState.Root>
  </Div100vh>
);

export default MaintenanceMode;
