import axios from "axios";
import { useMutation } from "react-query";

import { ToastNotification } from "@ag/utils/services";
import { CommonResponse } from "@ag/utils/types";

import { queryClient } from "~lib/react-query";

import { FarmFormValues } from "../components/farm-form/farm-form";
import { Farm } from "../entities/farm";
import { farmsKeys } from "./get-farms";

const addFarm = async ({ name, countryCode }: FarmFormValues) => {
  const response = await axios.post<CommonResponse<Farm>>(
    "/api/v1/farms",
    {
      name,
      countryCode,
    },
    {
      apiSource: "node-carbon",
    },
  );

  return response.data.success!.data;
};

export const useAddFarmMutation = () =>
  useMutation(addFarm, {
    onSuccess: () => {
      queryClient.invalidateQueries(farmsKeys.list());
    },
    onError: error => {
      ToastNotification.error(error);
    },
  });
