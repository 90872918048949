import axios from "axios";
import { useMutation } from "react-query";

import { ToastNotification } from "@ag/utils/services";

export type RecordCurrentUserActivityData = {
  event: string;
  parameters: Record<string, unknown>;
};

const recordCurrentUserActivity = async (
  data: RecordCurrentUserActivityData,
) => {
  await axios.post("/api/v1/users/activities", data, {
    apiSource: "node-auth",
  });
};

export const useRecordCurrentUserActivityMutation = () =>
  useMutation(recordCurrentUserActivity, {
    onError: error => {
      ToastNotification.error(error);
    },
  });
