export enum FieldDefinitionStatus {
  NotStarted = "not_started",
  InProgress = "in_progress",
  Completed = "completed",
  Submitted = "submitted",
}

// Having these duplicated enums is a short term situation until field_definitions endpoint is migrated to Node
export enum ExemptionReason {
  // NODE
  FALLOW = "Fallow",
  DROUGHT = "Drought",
  BIODIVERSITY = "Bio diversity",
  GRAZING = "Grazing",
  NOT_MANAGED = "Didn't have this field this year",
  OTHER = "Other",
}

export enum Tilling {
  NO_TILL = "no-till",
  MIN_TILL = "min-till",
  CONVENTIONAL = "conventional",
}

export enum CoverCrops {
  SPRING = "spring",
  WINTER = "winter",
  CATCH = "catch",
  NONE = "none",
}

export enum ResidueManagement {
  MULCHED = "mulched",
  BURNED = "burned",
  REMOVED = "removed",
}

export enum FuelType {
  DIESEL = "diesel",
  GASOLINE = "gasoline",
}

export enum SoilDrainage {
  GOOD = "Good",
  POOR = "Poor",
}

export enum LimingType {
  LIME = "lime",
  DOLOMITE = "dolomite",
  NONE = "none",
}

export enum SyntheticPhosphorusType {
  P = "P",
  P_2O5 = "P2O5",
}

export enum SyntheticPotassiumType {
  K = "K",
  K_2O = "K2O",
}
