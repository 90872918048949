import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react";
import { useContext } from "react";

import { AppHistoryContext } from "~contexts/app-history";

import ChunkLoadError from "./ChunkLoadError";
import GeneralError from "./GeneralError";
import { MAX_SENTRY_HISTORY_STACK_SIZE } from "./constants";

const ErrorBoundary = ({ children }: React.PropsWithChildrenRequired) => {
  const { historyStack } = useContext(AppHistoryContext);

  return (
    <SentryErrorBoundary
      fallback={({ error, resetError }) => {
        if (error.name === "ChunkLoadError") {
          return <ChunkLoadError />;
        }

        return <GeneralError onErrorReset={resetError} />;
      }}
      beforeCapture={scope => {
        scope.setContext("history", {
          historyStack: historyStack.slice(-MAX_SENTRY_HISTORY_STACK_SIZE), // Get last history items with the given size
        });
      }}
    >
      {children}
    </SentryErrorBoundary>
  );
};

export default ErrorBoundary;
