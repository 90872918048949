import axios from "axios";
import isNil from "lodash/isNil";
import { useQuery } from "react-query";

import {
  CommonPaginatedResponse,
  CommonResponse,
  createPaginatedDataSchema,
} from "@ag/utils/types";

import { FieldSchema } from "./schemas";
import {
  FetchFieldArgs,
  FetchFieldsArgs,
  Field,
  FieldKeyArgs,
  FieldsKeyArgs,
  FieldsResponse,
  UseFieldQueryArgs,
  UseFieldQueryOptions,
  UseFieldsQueryArgs,
  UseFieldsQueryOptions,
} from "./types";

export const fieldsKeys = {
  all: ["fields"],

  fieldsAll: () => [...fieldsKeys.all, "fields"],
  fields: (args?: FieldsKeyArgs) => [...fieldsKeys.fieldsAll(), args],

  fieldAll: () => [...fieldsKeys.all, "field"],
  field: (args: FieldKeyArgs) => [...fieldsKeys.fieldAll(), args],
} as const;

/**
 * QUERY FUNCTIONS
 */
export const fetchFields = async (args: FetchFieldsArgs = {}) => {
  const { filters, meta } = args;

  const limit = !isNil(meta) ? meta.limit : 5000;

  const response = await axios.get<CommonPaginatedResponse<Field>>(
    "/api/v1/carbon-fields",
    {
      params: {
        limit,
        page: meta?.page,
        filters,
      },
      apiSource: "node-carbon",
      schema: createPaginatedDataSchema(
        FieldSchema.omit({
          carbonFieldDefinition: true,
        }),
      ),
    },
  );

  return response.data.success!.data;
};

const fetchField = async ({ fieldId, harvestYear }: FetchFieldArgs) => {
  const response = await axios.get<CommonResponse<Field>>(
    `/api/v1/carbon-fields/${fieldId}`,
    {
      params: {
        harvestYear,
      },
      apiSource: "node-carbon",
      schema: FieldSchema,
    },
  );

  return response.data.success!.data;
};

/**
 * QUERY HOOKS
 */
export const useFieldsQuery = <T = FieldsResponse>(
  args?: UseFieldsQueryArgs,
  options?: UseFieldsQueryOptions<T>,
) => useQuery(fieldsKeys.fields(args), () => fetchFields(args), options);

export const useFieldQuery = (
  args: UseFieldQueryArgs,
  options?: UseFieldQueryOptions,
) =>
  useQuery(
    fieldsKeys.field(args),
    () => {
      const { fieldId } = args;

      if (!fieldId) return Promise.resolve(undefined);

      return fetchField({
        ...args,
        fieldId,
      });
    },
    {
      enabled: Boolean(args.fieldId),
      ...options,
    },
  );
