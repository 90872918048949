import axios from "axios";
import { useMutation } from "react-query";

import { ToastNotification } from "@ag/utils/services";
import { APINoContentResponse } from "@ag/utils/types";

import { queryClient } from "~lib/react-query";
import { fieldsKeys } from "~queries/carbon/fields";

import { fieldDefinitionsKeys } from "./get-field-definition";

export const submitFieldDefinition = async (
  fieldId: string,
  fieldDefinitionId: number,
) => {
  await axios.post<APINoContentResponse>(
    `/api/v1/carbon-fields/${fieldId}/definitions/${fieldDefinitionId}/submit`,
    {},
    {
      apiSource: "node-carbon",
    },
  );
};

export const useFieldDefinitionSubmitMutation = (
  fieldId: string,
  fieldDefinitionId: number,
  options?: { onSuccess?: () => void },
) =>
  useMutation(() => submitFieldDefinition(fieldId, fieldDefinitionId), {
    onSuccess: async () => {
      const invalidateFieldDefinitionRequest = queryClient.invalidateQueries(
        fieldDefinitionsKeys.fieldDefinition(fieldId),
      );

      const invalidateFieldRequest = queryClient.invalidateQueries(
        fieldsKeys.field({ fieldId }),
        {
          exact: false,
        },
      );

      await Promise.all([
        invalidateFieldDefinitionRequest,
        invalidateFieldRequest,
      ]);

      if (options?.onSuccess) options?.onSuccess();
    },
    onError: error => {
      ToastNotification.error(error);
    },
  });
