import { useAtom } from "jotai";
import { Controller, useFormContext } from "react-hook-form";

import { ButtonSelect } from "@ag/design-system/molecules";
import { ButtonSelectField } from "@ag/form-fields";
import I18n from "@ag/i18n";

import FormSection from "~components/form-section";

import { isDisabledAtom } from "../atoms/is-disabled-atom";
import { type FieldDefinitionData } from "../schemas/field-definition-form";

const GeneralFieldFormSection = () => {
  const [isDisabled] = useAtom(isDisabledAtom);

  const {
    control,
    formState: { errors },
  } = useFormContext<FieldDefinitionData>();

  return (
    <FormSection.Root
      title={I18n.t(
        "js.carbon.field_details.field_definition.questionnaire.general.title",
      )}
    >
      <FormSection.Card>
        <Controller
          name="verraQuestionnaire.fieldArableInPastTenYears"
          control={control}
          render={({ field }) => (
            <ButtonSelectField
              {...field}
              label={I18n.t(
                "js.carbon.field_details.field_definition.questionnaire.general.field_arable",
              )}
              testId="field-arable-in-past-ten-years"
              error={errors.verraQuestionnaire?.fieldArableInPastTenYears}
              isDisabled={isDisabled}
              isRequired
            >
              <ButtonSelect.Option value={true}>
                {I18n.t("js.shared.yes")}
              </ButtonSelect.Option>

              <ButtonSelect.Option value={false}>
                {I18n.t("js.shared.no")}
              </ButtonSelect.Option>
            </ButtonSelectField>
          )}
        />
      </FormSection.Card>

      <FormSection.Card>
        <Controller
          name="soilMgmtHistoricConversion"
          control={control}
          render={({ field }) => (
            <ButtonSelectField
              {...field}
              label={I18n.t(
                "js.carbon.field_details.field_definition.questionnaire.general.field_converted",
              )}
              testId="soil-mgmt-historic-conversion"
              error={errors.soilMgmtHistoricConversion}
              isDisabled={isDisabled}
              isRequired
            >
              <ButtonSelect.Option value={true}>
                {I18n.t("js.shared.yes")}
              </ButtonSelect.Option>

              <ButtonSelect.Option value={false}>
                {I18n.t("js.shared.no")}
              </ButtonSelect.Option>
            </ButtonSelectField>
          )}
        />
      </FormSection.Card>
    </FormSection.Root>
  );
};

export default GeneralFieldFormSection;
