"use client";

import { Experiment, ExperimentClient } from "@amplitude/experiment-js-client";
import { sessionReplayPlugin } from "@amplitude/plugin-session-replay-browser";
import {
  type ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { useEffectOnce } from "@ag/utils/hooks";

import { env } from "~config";

import { ampli } from "./generated";

type AmplitudeUpdateUserOptions = {
  isAdminMode?: boolean;
  locale?: string;
  farmSize?: number;
};

type AmplitudeContextValue = {
  isLoaded: boolean;
  experiment: ExperimentClient | null;
  updateUser: (
    userId: string | undefined,
    options: AmplitudeUpdateUserOptions,
  ) => void;
};

export const AmplitudeContext = createContext<AmplitudeContextValue>({
  isLoaded: false,
  updateUser: () => {},
  experiment: null,
});

type AmplitudeProviderProps = {
  apiKey: string;
  cookiebotEnabled: boolean;
  sessionReplayEnabled: boolean;
  children: ReactNode;
};

export function AmplitudeProvider({
  children,
  apiKey,
  cookiebotEnabled,
  sessionReplayEnabled,
}: AmplitudeProviderProps) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const experiment = useRef<ExperimentClient | null>(null);

  const [isOptedIn, setIsOptedIn] = useState(
    window.Cookiebot?.consent?.statistics === true || !cookiebotEnabled,
  );

  useEffectOnce(() => {
    (async () => {
      ampli.load({
        client: {
          apiKey,
          configuration: {
            defaultTracking: true,
            transport: "beacon",
            optOut: !isLoggedIn && !isOptedIn,
          },
        },
      });

      // Adds session replays to get detailed insights on user behavior.
      ampli.client.add(
        sessionReplayPlugin({ sampleRate: sessionReplayEnabled ? 1 : 0 }),
      );

      experiment.current = Experiment.initializeWithAmplitudeAnalytics(
        env.REACT_APP_AMPLITUDE_API_KEY as string,
        {
          serverZone: "eu",
        },
      );

      await experiment.current.start();

      setIsLoaded(true);
    })();
  });

  useEffect(() => {
    // We assume logged in means statistics consent (ask Aleksander Shibilev for details if needed)
    ampli.client.setOptOut(!isLoggedIn && !isOptedIn);
  }, [isOptedIn, isLoggedIn]);

  const updateUser = useCallback(
    async (userId: string | undefined, options: AmplitudeUpdateUserOptions) => {
      ampli.identify(userId, options);
      setIsLoggedIn(Boolean(userId));

      if (!experiment.current) return;

      experiment.current.setUser({ user_id: userId });
      await experiment.current.fetch();
    },
    [],
  );

  /**
   * Set up cookiebot listeners for loaded/updated actions
   */
  useEffect(() => {
    // If we are on an environment with no cookie support, early exit.
    if (!cookiebotEnabled) return;

    const onConsentChanged = () => {
      setIsOptedIn(Boolean(window.Cookiebot?.consent?.statistics));
    };

    /**
     * Whenever the user updates constent, update the amplitude state.
     * This is also fired on subsequent visits to the page after a user
     * has already made a choice.
     */
    window.addEventListener("CookiebotOnConsentReady", onConsentChanged);

    /**
     * Depending on timing, this script can run when Cookiebot is already initiliased,
     * meaning the "CookiebotOnConsentReady" event listener is too late.
     * In that case, fire the update listener.
     */
    if (window.Cookiebot) onConsentChanged();

    return () => {
      window.removeEventListener("CookiebotOnConsentReady", onConsentChanged);
    };
  }, [cookiebotEnabled]);

  const value = useMemo(
    () => ({
      experiment: experiment.current,
      isLoaded,
      updateUser,
    }),
    [isLoaded, updateUser],
  );

  return (
    <AmplitudeContext.Provider value={value}>
      {children}
    </AmplitudeContext.Provider>
  );
}

/**
 * Check if the given flag is enabled for the current user.
 *
 * NOTE: This assumes that you wait for the flags to load before receiving this value.
 * That is done automatically for authorized routes, but not for pre-login routes.
 * For pre-login cases, you should use `useContext(AmplitudeContext)` to check if amplitude is loaded.
 */
export const useIsFlagActive = (name: string) => {
  const { experiment } = useContext(AmplitudeContext);

  if (!experiment) throw new Error("Experiment not available");

  return experiment.variant(name).value === "on";
};
