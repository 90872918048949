import { z } from "zod";

import {
  AdditionalInformationV2021DataSchema,
  CropProtectionV2021DataSchema,
  CultivationStrategyV2021DataSchema,
  FertiliserV2021DataSchema,
  FuelConsumptionV2021DataSchema,
} from "@ag/carbon/forms/inputs-v2021";
import {
  AdditionalInformationV2022DataSchema,
  CropProtectionV2022DataSchema,
  CultivationStrategyV2022DataSchema,
  FertiliserV2022DataSchema,
  FuelConsumptionV2022DataSchema,
} from "@ag/carbon/forms/inputs-v2022";

import { FieldActualsInputType, FieldActualsStatus } from "./types";

export const FieldActualsSchema = z.object({
  carbonFieldActualInputType: z.nativeEnum(FieldActualsInputType),
  completedAt: z.string().nullable(),
  completionRate: z.number(),
  id: z.string(),
  readonly: z.boolean(),
  submittedAt: z.string().nullable(),
  fallow: z.boolean(),
  status: z.nativeEnum(FieldActualsStatus),
  methodologyVersion: z.number(),
  harvestYear: z.number(),
});

export const FieldActualsInputV2021Schema =
  CultivationStrategyV2021DataSchema.merge(FertiliserV2021DataSchema)
    .merge(CropProtectionV2021DataSchema)
    .merge(FuelConsumptionV2021DataSchema)
    .merge(AdditionalInformationV2021DataSchema);

export const FieldActualsV2021ExtendedSchema = FieldActualsSchema.merge(
  z.object({
    carbonFieldActualInputType: z.literal(FieldActualsInputType.V2021),
    carbonFieldActualInput: FieldActualsInputV2021Schema,
  }),
);

export const FieldActualsInputV2022Schema =
  CultivationStrategyV2022DataSchema.merge(FertiliserV2022DataSchema)
    .merge(CropProtectionV2022DataSchema)
    .merge(FuelConsumptionV2022DataSchema)
    .merge(AdditionalInformationV2022DataSchema);

export const FieldActualsV2022ExtendedSchema = FieldActualsSchema.merge(
  z.object({
    carbonFieldActualInputType: z.literal(FieldActualsInputType.V2022),
    carbonFieldActualInput: FieldActualsInputV2022Schema,
  }),
);

export const FieldActualsExtendedSchema = z.discriminatedUnion(
  "carbonFieldActualInputType",
  [FieldActualsV2021ExtendedSchema, FieldActualsV2022ExtendedSchema],
);

export const FieldActualsInputSchema = z.union([
  FieldActualsInputV2021Schema,
  FieldActualsInputV2022Schema,
]);
