import { colorsObject } from "@ag/design-system/tokens";
import { MAP_STYLES, MAX_ZOOM, MIN_ZOOM } from "@ag/map/constants";

export const DEFAULT_MAP_OPTIONS: google.maps.MapOptions = {
  mapTypeControl: false,
  fullscreenControl: false,
  streetViewControl: false,
  zoomControl: true,
  styles: [
    ...MAP_STYLES,
    {
      featureType: "administrative",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: colorsObject.grey.white,
        },
      ],
    },
  ],
  gestureHandling: "greedy",
  minZoom: MIN_ZOOM,
  maxZoom: MAX_ZOOM,
  controlSize: 24,
  mapTypeId: "hybrid",
};

export const DEFAULT_POLYGON_OPTIONS: google.maps.PolygonOptions = {
  strokeColor: colorsObject.messaging.info[300],
  strokeWeight: 3,
  fillColor: colorsObject.messaging.info[300],
  fillOpacity: 0.15,
};

export const DEFAULT_ZOOM_LEVEL = 16;
