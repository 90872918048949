import { z } from "zod";

import { FieldStatus } from "./types";

export const CarbonStatsSchema = z.object({
  estimated: z.boolean(),
  createdAt: z.string().nullable(),
  updatedAt: z.string().nullable(),
  grossReduction: z.number().nullable(),
  numberOfCertificates: z.number().nullable(),
  numberOfFields: z.number(),
  totalHectars: z.number(),
  validated: z.boolean(),
  verified: z.boolean(),
});

export const CarbonHarvestYearsStatsSchema = z.record(
  z.string(),
  z.record(z.nativeEnum(FieldStatus), CarbonStatsSchema),
);
