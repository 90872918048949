import styled from "@emotion/styled";

import { Card } from "@ag/design-system/organisms";
import { colorsObject } from "@ag/design-system/tokens";

export const SLearnMoreButtonContainer = styled.div`
  text-align: center;
`;

export const SRoot = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${colorsObject.grey.white};
  position: relative;
  padding-top: 0;
`;
