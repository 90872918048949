import { useMemo } from "react";
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
} from "react-hook-form";
import { z } from "zod";

import { ComboBox } from "@ag/design-system/molecules";
import { ComboBoxField, InputField } from "@ag/form-fields";
import I18n from "@ag/i18n";

import { ampli } from "~lib/ampli";
import { useCarbonCountriesQuery } from "~queries/carbon/carbon-countries";

import * as styles from "./farm-form.css";

export const farmFormSchema = (companyCountryCode?: string) =>
  z.object({
    name: z
      .string({ required_error: I18n.t("js.shared.required") })
      .min(1, I18n.t("js.shared.required")),
    countryCode: z
      .string({ required_error: I18n.t("js.shared.required") })
      .min(1, I18n.t("js.shared.required"))
      .refine(
        countryCode => countryCode === companyCountryCode,
        I18n.t("js.farmer.farm_form.add_farm.form.validation.wrong_country"),
      ),
  });

export type FarmFormValues = z.infer<ReturnType<typeof farmFormSchema>>;

type Props = {
  register: UseFormRegister<FarmFormValues>;
  errors: FieldErrors<FarmFormValues>;
  control: Control<FarmFormValues>;
};

const FarmForm = ({ register, errors, control }: Props) => {
  const countries = useCarbonCountriesQuery({
    isActiveMarket: true,
  });

  const countriesOptions = useMemo(
    () =>
      countries.data?.map(country => ({
        label: country.name,
        value: country.code,
      })),
    [countries],
  );

  return (
    <form className={styles.form}>
      <InputField
        {...register("name")}
        label={I18n.t("js.farmer.farm_form.add_farm.name_label")}
        error={errors.name}
      />

      <Controller
        name="countryCode"
        control={control}
        render={({ field }) => (
          <ComboBoxField
            {...field}
            emptyText={I18n.t("js.shared.no_matching_results")}
            error={errors.countryCode}
            label={I18n.t("js.farmer.farm_form.add_farm.country_label")}
            loadingText={I18n.t("js.shared.loading")}
            menuTriggger="focus"
            optionsClassName="z-modal"
            placeholder={I18n.t(
              "js.missing_profile_information.form.country.placeholder",
            )}
            isLoading={countries.isLoading}
            onChange={value => {
              if (value) {
                ampli.inputFilled({
                  inputName: "Country",
                  inputValue: value,
                });
              }

              field.onChange(value);
            }}
          >
            {countriesOptions?.map(option => (
              <ComboBox.Item key={option.value}>{option.label}</ComboBox.Item>
            ))}
          </ComboBoxField>
        )}
      />
    </form>
  );
};

export default FarmForm;
