import styled from "@emotion/styled";

const size = {
  container: {
    width: 360,
  },
  icon: {
    width: 90,
  },
};

export const SContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${size.container.width}px;
  margin: 100px auto 0 auto;
`;

export const SLogo = styled.img`
  width: 120px;
`;

export const SIcon = styled.img`
  display: inline-block;
  width: ${size.icon.width}px;
  margin: 32px 0;
`;

export const SSeconds = styled.span`
  margin-left: 4px;
  font-weight: 700;
`;
