import { typography } from "@ag/design-system/tokens";
import I18n from "@ag/i18n";

const AdditionalInformationTip = () => (
  <p className={typography.h6}>
    {I18n.t(
      "js.carbon.field_details.field_definition.questionnaire.additional_info_tip",
    )}
  </p>
);

export default AdditionalInformationTip;
