import { type EffectCallback, useEffect, useRef, useState } from "react";

/**
 * Custom hook that runs an effect only once when the component is mounted.
 *
 * @param effect - The effect function to be executed only once.
 */
export function useEffectOnce(effect: EffectCallback) {
  const effectFn = useRef<() => void | (() => void)>(effect);
  const destroyFn = useRef<void | (() => void)>();
  const effectCalled = useRef(false);
  const rendered = useRef(false);
  const [, setVal] = useState<number>(0);

  if (effectCalled.current) {
    rendered.current = true;
  }

  useEffect(() => {
    // Execute the effect only on the first render
    if (!effectCalled.current) {
      destroyFn.current = effectFn.current();
      effectCalled.current = true;
    }

    // Forces one render after the effect to enable cleanup
    setVal(val => val + 1);

    return () => {
      // If the component did not render since the effect call, it's a dummy cycle
      if (!rendered.current) {
        return;
      }

      // Call the destroy function if it's not a dummy cycle
      if (destroyFn.current) {
        destroyFn.current();
      }
    };
  }, []);
}
