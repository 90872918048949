import { Link, useLocation } from "react-router-dom";

import { Button } from "@ag/design-system/atoms";
import { Modal } from "@ag/design-system/organisms";
import I18n from "@ag/i18n";

import { ModalContentProps } from "~components/GlobalModal";

const TranslatorUsageWarning = ({ isOpen, onClose }: ModalContentProps) => {
  const { pathname } = useLocation();
  const isRegistrationModule =
    pathname.includes("registration") || pathname.includes("login");

  return (
    <Modal.Root isOpen={isOpen} onRequestClose={onClose}>
      <Modal.Title>{I18n.t("js.sessions.new.oooops")}</Modal.Title>

      <Modal.Content>
        {I18n.t("js.shared.extensions.translator_warning")}

        <div>
          {isRegistrationModule ? (
            I18n.t("js.shared.extensions.please_choose_language")
          ) : (
            <Link to="/account" onClick={onClose}>
              {I18n.t("js.shared.please_change_your_language")}
            </Link>
          )}
        </div>
      </Modal.Content>

      <Modal.Footer
        render={onClose => (
          <Button onClick={onClose}>{I18n.t("js.shared.ok")}</Button>
        )}
      />
    </Modal.Root>
  );
};

export default TranslatorUsageWarning;
