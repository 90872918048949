import { useAtom } from "jotai";
import { Controller, useFormContext } from "react-hook-form";

import { ButtonSelect } from "@ag/design-system/molecules";
import { ButtonSelectField } from "@ag/form-fields";
import I18n from "@ag/i18n";

import FormSection from "~components/form-section";

import { isDisabledAtom } from "../../atoms/is-disabled-atom";
import { type FieldDefinitionData } from "../../schemas/field-definition-form";
import {
  CoverCrops,
  ResidueManagement,
} from "../../types/field-definition-data";
import { YearKey } from "./crop-form-section";

const CoverCropsFormSection = ({ yearKey }: { yearKey: YearKey }) => {
  const [isDisabled] = useAtom(isDisabledAtom);

  const { control, formState } = useFormContext<FieldDefinitionData>();
  const errors = formState.errors.verraQuestionnaire?.[yearKey];

  return (
    <FormSection.Root
      title={I18n.t(
        "js.carbon.field_details.field_definition.questionnaire.cover_crops_residue.title",
      )}
    >
      <FormSection.Card>
        <Controller
          name={`verraQuestionnaire.${yearKey}.coverCrops`}
          control={control}
          render={({ field }) => (
            <ButtonSelectField
              {...field}
              label={I18n.t(
                "js.carbon.field_details.field_definition.questionnaire.cover_crops.title",
              )}
              testId="cover-crops"
              error={errors?.coverCrops}
              isDisabled={isDisabled}
              isRequired
            >
              <ButtonSelect.Option value={CoverCrops.SPRING}>
                {I18n.t(
                  "js.carbon.field_details.field_definition.questionnaire.cover_crops.cover_crop_until_spring",
                )}
              </ButtonSelect.Option>

              <ButtonSelect.Option value={CoverCrops.WINTER}>
                {I18n.t(
                  "js.carbon.field_details.field_definition.questionnaire.cover_crops.cover_crop_until_winter",
                )}
              </ButtonSelect.Option>

              <ButtonSelect.Option value={CoverCrops.CATCH}>
                {I18n.t(
                  "js.carbon.field_details.field_definition.questionnaire.cover_crops.catch_crop",
                )}
              </ButtonSelect.Option>

              <ButtonSelect.Option value={CoverCrops.NONE}>
                {I18n.t(
                  "js.carbon.field_details.field_definition.questionnaire.cover_crops.none",
                )}
              </ButtonSelect.Option>
            </ButtonSelectField>
          )}
        />
      </FormSection.Card>

      <FormSection.Card>
        <Controller
          name={`verraQuestionnaire.${yearKey}.residueManagement`}
          control={control}
          render={({ field }) => (
            <ButtonSelectField
              {...field}
              label={I18n.t(
                "js.carbon.field_details.field_definition.questionnaire.crop_residue.title",
              )}
              testId="residue-management"
              error={errors?.residueManagement}
              isDisabled={isDisabled}
              isRequired
            >
              <ButtonSelect.Option value={ResidueManagement.MULCHED}>
                {I18n.t(
                  "js.carbon.field_details.field_definition.questionnaire.crop_residue.mulched",
                )}
              </ButtonSelect.Option>

              <ButtonSelect.Option value={ResidueManagement.REMOVED}>
                {I18n.t(
                  "js.carbon.field_details.field_definition.questionnaire.crop_residue.removed",
                )}
              </ButtonSelect.Option>

              <ButtonSelect.Option value={ResidueManagement.BURNED}>
                {I18n.t(
                  "js.carbon.field_details.field_definition.questionnaire.crop_residue.burnt",
                )}
              </ButtonSelect.Option>
            </ButtonSelectField>
          )}
        />
      </FormSection.Card>
    </FormSection.Root>
  );
};

export default CoverCropsFormSection;
