import isEmpty from "lodash/isEmpty";
import { useState } from "react";

import { Button } from "@ag/design-system/atoms";
import { Modal } from "@ag/design-system/organisms";
import I18n from "@ag/i18n";
import { ToastNotification } from "@ag/utils/services";

import { ModalContentProps } from "~components/GlobalModal";
import { SAddFieldsContent } from "~components/GlobalModal/modals/validation-flow/styled";
import { queryClient } from "~lib/react-query";
import { fieldsKeys } from "~queries/carbon/fields";
import {
  ValidationRequestStatus,
  useValidationRequestsQuery,
} from "~queries/carbon/validation-requests";
import { useUpdateValidationRequestMutation } from "~queries/carbon/validation-requests/mutations";

import FieldsForValidation from "../components/FieldsForValidation";
import { SDescription } from "../styled";
import ShowValidatedFields from "./components/ShowValidatedFields";

export type Extras = {
  harvestYear: number;
};

const AddMoreFieldsToValidationRequest = ({
  extras,
  isOpen,
  onClose,
}: ModalContentProps) => {
  const [carbonFieldIds, setCarbonFieldIds] = useState<number[] | null>(null);

  const harvestYear = Number((extras as Extras)?.harvestYear);

  const updateValidationRequestMutation = useUpdateValidationRequestMutation();
  const { data: validationRequests } = useValidationRequestsQuery();

  const handleAddMoreFieldsClicked = () => {
    if (isEmpty(carbonFieldIds)) {
      ToastNotification.warning(
        I18n.t("js.carbon.please_choose_fields_to_validate"),
      );

      return;
    }

    const pendingValidationRequest = validationRequests?.find(
      validationRequest =>
        validationRequest.status === ValidationRequestStatus.New &&
        validationRequest.harvestYear === harvestYear,
    );

    if (carbonFieldIds && pendingValidationRequest && onClose) {
      updateValidationRequestMutation.mutate(
        {
          requestId: pendingValidationRequest.id,
          carbonFieldIds: carbonFieldIds.map(String),
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(fieldsKeys.fieldsAll());

            onClose();

            ToastNotification.success(
              I18n.t("js.carbon.validation_application_has_been_updated"),
            );
          },
        },
      );
    }
  };

  return (
    <Modal.Root isOpen={isOpen} onRequestClose={onClose}>
      <Modal.Title>{I18n.t("js.carbon.add_more_fields")}</Modal.Title>

      <Modal.Content>
        <SDescription
          className="text-p2"
          dangerouslySetInnerHTML={{
            __html: I18n.t("js.carbon.about_to_select_fields_for_validation"),
          }}
        />

        <SAddFieldsContent>
          <ShowValidatedFields />

          <FieldsForValidation
            harvestYear={harvestYear}
            onChosenFieldsUpdate={fieldsToBeSubmitted => {
              const fieldIdsAddedToValidate = Object.keys(fieldsToBeSubmitted)
                .filter((key: string) => fieldsToBeSubmitted[Number(key)])
                .map(Number);

              setCarbonFieldIds(fieldIdsAddedToValidate);
            }}
          />
        </SAddFieldsContent>
      </Modal.Content>

      <Modal.Footer
        render={() => (
          <>
            <Button variant="text" onClick={onClose}>
              {I18n.t("js.shared.cancel")}
            </Button>

            <Button
              isLoading={updateValidationRequestMutation.isLoading}
              onClick={handleAddMoreFieldsClicked}
            >
              {I18n.t("js.carbon.add_more_fields")}
            </Button>
          </>
        )}
      ></Modal.Footer>
    </Modal.Root>
  );
};

export default AddMoreFieldsToValidationRequest;
