import axios from "axios";
import { useQuery } from "react-query";
import { z } from "zod";

import { CommonResponse } from "@ag/utils/types";

import { CarbonCountry, CarbonCountrySchema } from "./schemas";
import { CarbonCountriesFilters } from "./types";

export const generateCarbonCountriesKey = (filters?: CarbonCountriesFilters) =>
  ["carbon-countries", filters].filter(Boolean);

/**
 * QUERY FUNCTIONS
 */
const getCarbonCountries = async (
  filters?: CarbonCountriesFilters,
): Promise<CarbonCountry[]> => {
  const response = await axios.get<CommonResponse<CarbonCountry[]>>(
    "/api/v1/carbon-countries",
    {
      params: {
        filters,
      },
      apiSource: "node-carbon",
      schema: z.array(CarbonCountrySchema),
    },
  );

  return response.data.success!.data;
};

/**
 * QUERY HOOKS
 */
export const useCarbonCountriesQuery = (filters?: CarbonCountriesFilters) =>
  useQuery<CarbonCountry[]>(
    generateCarbonCountriesKey(filters),
    () => getCarbonCountries(filters),
    {
      staleTime: Infinity,
    },
  );
