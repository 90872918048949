import styled from "@emotion/styled";

export const SPage404 = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const SImage = styled.img`
  margin-top: 44px;
`;

export const SContent = styled.h3`
  margin: 30px 0 34px;
`;
