import { env } from "~config";

export const setupCookiebot = () => {
  const id = env.REACT_APP_COOKIEBOT_KEY;
  if (!id) return;

  const script = document.createElement("script");
  const head = document.head || document.getElementsByTagName("head")[0];

  script.id = "Cookiebot";
  script.src = "https://consent.cookiebot.com/uc.js";
  script.dataset.cbid = id;
  script.dataset.blockingmode = "auto";
  script.async = true;
  script.type = "text/javascript";

  head.insertBefore(script, head.firstChild);
};
