import I18n from "i18n-js";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { Locale, updateTranslations } from "@ag/i18n";
import { loadTranslations } from "@ag/i18n/app";

import { AmplitudeContext, ampli } from "~lib/ampli";

export type LocaleContextType = {
  locale: Locale;
  setLocale: (locale: Locale) => void;
};

const LocaleContext = createContext<LocaleContextType | undefined>(undefined);

export const LocaleProvider = ({
  children,
}: React.PropsWithChildrenRequired) => {
  const [locale, setLocale] = useState<Locale>(I18n.locale as Locale);
  const { updateUser } = useContext(AmplitudeContext);

  const handleLocaleChange = useCallback(async (locale: Locale) => {
    const translations = await loadTranslations(locale);
    updateTranslations(locale, translations);

    setLocale(locale);
  }, []);

  useEffect(() => {
    const ampliInstance = ampli.client;

    // Set locale for the session
    if (ampliInstance) {
      updateUser(ampliInstance.getUserId(), {
        locale,
      });
    }
  }, [locale, updateUser]);

  return (
    <LocaleContext.Provider value={{ locale, setLocale: handleLocaleChange }}>
      {children}
    </LocaleContext.Provider>
  );
};

export const useLocaleContext = () => {
  const context = useContext(LocaleContext);

  if (context === undefined) {
    throw new Error("useLocaleContext must be used within a LocaleProvider");
  }

  return context;
};
