import axios from "axios";
import { QueryObserverOptions, useQuery } from "react-query";

import { CommonResponse } from "@ag/utils/types";

import { FieldDefinition } from "../schemas/field-definition";

export const fieldDefinitionsKeys = {
  all: ["field-definitions"] as const,

  fieldDefinition: (fieldId: string) =>
    [...fieldDefinitionsKeys.all, "field-definition", { fieldId }] as const,
};

/**
 * QUERY FUNCTIONS
 */
const fetchFieldDefinition = async (fieldId: string) => {
  const response = await axios.get<CommonResponse<FieldDefinition>>(
    `/api/v1/carbon-fields/${fieldId}/definitions`,
    {
      apiSource: "node-carbon",
    },
  );

  return response.data.success!.data;
};

/**
 * QUERY HOOKS
 */
export const useFieldDefinitionQuery = (
  fieldId: string,
  options?: Pick<QueryObserverOptions, "enabled">,
) =>
  useQuery(
    fieldDefinitionsKeys.fieldDefinition(fieldId),
    () => fetchFieldDefinition(fieldId),
    options,
  );
