import {
  FieldDefinitionData,
  FieldPractice,
} from "../schemas/field-definition-form";
import { FieldDefinitionUpdateData } from "../schemas/update-field-definition";

const convertFieldPracticeYearValuesToApiPayload = ({
  exemptionReason,
  ...rest
}: FieldPractice): Partial<FieldPractice> => {
  // Remove other properties if exemptionReason is present
  const filteredRest = exemptionReason
    ? { exemptionReason }
    : (rest as Partial<FieldPractice>);

  // Form field could have undefined but API expects null
  if (filteredRest?.totalYield === undefined) {
    filteredRest.totalYield = null;
  }

  return filteredRest;
};

export const convertFormValuesToApiPayload = ({
  verraQuestionnaire,
  ...values
}: FieldDefinitionData): FieldDefinitionUpdateData => ({
  ...values,
  verraQuestionnaire: {
    ...verraQuestionnaire,
    fieldPracticesOneYearAgo: convertFieldPracticeYearValuesToApiPayload(
      verraQuestionnaire?.fieldPracticesOneYearAgo as FieldPractice,
    ),
    fieldPracticesTwoYearsAgo: convertFieldPracticeYearValuesToApiPayload(
      verraQuestionnaire?.fieldPracticesTwoYearsAgo as FieldPractice,
    ),
    fieldPracticesThreeYearsAgo: convertFieldPracticeYearValuesToApiPayload(
      verraQuestionnaire?.fieldPracticesThreeYearsAgo as FieldPractice,
    ),
    fieldPracticesFourYearsAgo: convertFieldPracticeYearValuesToApiPayload(
      verraQuestionnaire?.fieldPracticesFourYearsAgo as FieldPractice,
    ),
    fieldPracticesFiveYearsAgo: convertFieldPracticeYearValuesToApiPayload(
      verraQuestionnaire?.fieldPracticesFiveYearsAgo as FieldPractice,
    ),
  },
});
