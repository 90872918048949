import { z } from "zod";

import {
  FieldActualsExtendedSchema,
  FieldActualsInputSchema,
  FieldActualsInputV2021Schema,
  FieldActualsInputV2022Schema,
  FieldActualsSchema,
  FieldActualsV2021ExtendedSchema,
  FieldActualsV2022ExtendedSchema,
} from "./schemas";

/**
 * ENTITIES
 */
export type FieldActuals = z.infer<typeof FieldActualsSchema>;

export type FieldActualsV2021Extended = z.infer<
  typeof FieldActualsV2021ExtendedSchema
>;

export type FieldActualsV2022Extended = z.infer<
  typeof FieldActualsV2022ExtendedSchema
>;

export type FieldActualsExtended = z.infer<typeof FieldActualsExtendedSchema>;

export type FieldActualsInputV2021 = z.infer<
  typeof FieldActualsInputV2021Schema
>;
export type FieldActualsInputV2022 = z.infer<
  typeof FieldActualsInputV2022Schema
>;

export type FieldActualsInput = z.infer<typeof FieldActualsInputSchema>;

/**
 * RESPONSES
 */

/**
 * QUERY KEY ARGS
 */
export type ActualsQueryKeyArgs = {
  fieldId: string | undefined;
  fieldActualsId: number | string | undefined;
};

/**
 * QUERY ARGS
 */
export type UseFieldActualsQueryArgs = {
  // TODO: Change to string when everything around actuals will be migrated
  fieldId: string | undefined;
  fieldActualsId: number | string | undefined;
};

export type UseFieldActualsQueryOptions<T> = {
  enabled?: boolean;
  select?: (fieldActuals: FieldActualsExtended | undefined) => T;
};

export type UseFieldActualsStatusArgs = {
  fieldId: string;
  fieldActualsId: string | undefined;
};

/**
 * QUERY FUNCTION ARGS
 */
export type FetchActualsArgs = {
  // TODO: Change to string when everything around actuals will be migrated
  fieldId: string;
  fieldActualsId: number | string;
};

/**
 * MUTATION ARGS
 */
export type UseCopyDataFromStrategyMutationArgs = {
  fieldId: string;
  fieldActualsId: number | undefined;
  attributes:
    | (keyof FieldActualsInputV2021)[]
    | (keyof FieldActualsInputV2022)[];
};

/**
 * MUTATION FUNCTION ARGS
 */
export type UpdateFieldActualsArgs<I extends FieldActualsInput> = {
  // TODO: Change to string when everything around actuals will be migrated
  fieldId: string;
  fieldActualsId: number | string;
  fieldActuals: {
    fallow?: boolean | null;
    carbonFieldActualInput?: Partial<I>;
  };
};

export type AdminUpdateFieldActualsArgs<I extends FieldActualsInput> = {
  fieldId: string;
  fieldActualsId: string;
  fieldActuals: {
    fallow?: boolean | null;
    carbonFieldActualInput?: Partial<I>;
    comment: {
      text: string;
    };
  };
};

export type SubmitFieldActualsArgs = {
  fieldId?: string;
  carbonFieldActualIds: string[];
};

export enum FieldActualsPartial {
  Fallow = "FALLOW",
  CultivationStrategy = "CULTIVATION_STRATEGY",
  Fertiliser = "FERTILISER",
  CropProtection = "CROP_PROTECTION",
  FuelConsumption = "FUEL_CONSUMPTION",
  AdditionalInformation = "ADDITIONAL_INFORMATION",
}

// Written in snake_case to be used in the translation key
export enum FieldActualsStatus {
  NotStarted = "not_started",
  InProgress = "in_progress",
  Completed = "completed",
  Submitted = "submitted",
}

export enum FieldActualsInputType {
  V2021 = "CarbonFieldActualV2021Input",
  V2022 = "CarbonFieldActualV2022Input",
}
