import { ComponentProps } from "react";
import SkeletonLoader from "react-skeleton-loader";

import { colorsObject } from "@ag/design-system/tokens";

import {
  marginBottomVariants,
  marginTopVariants,
  sSkeletonWrapper,
} from "./styled.css";

type OwnProps = {
  color?: string;
  marginTop?: keyof typeof marginTopVariants;
  marginBottom?: keyof typeof marginBottomVariants;
  dark?: boolean;
  randomWidth?: boolean;
  fullWidth?: boolean;
};

type Props = OwnProps &
  Partial<
    Pick<
      ComponentProps<typeof SkeletonLoader>,
      "height" | "width" | "borderRadius"
    >
  >;

/**
 * Generic placeholder component for page content loading.
 * @param color optional loader bg color (default "#EFF1F6")
 * @param width optional (max) width of the element (default "150px")
 * @param height optional (max) height of your element (default "100%")
 * @param borderRadius optional border-radius in px (default 4)
 * @param marginTop optional top spacing in px (default 0)
 * @param marginBottom optional bottom spacing in px (default 0)
 * @param randomWidth optional if skeleton has width variation. If true, 0.75 - 1 of width (default false)
 * @param fullWidth optional maxWidth set to 100% (default false)
 * @param dark optional set background color to "#EFEFEF" (default false)
 */
const Skeleton = ({
  color,
  dark,
  marginTop,
  marginBottom,
  randomWidth,
  fullWidth,
  borderRadius,
  width,
  ...rest
}: Props) => (
  <span
    className={sSkeletonWrapper({
      fullWidth,
      marginBottom,
      marginTop,
      inline: Boolean(marginBottom || marginTop),
    })}
  >
    <SkeletonLoader
      color={dark ? colorsObject.grey[200] : color}
      borderRadius={borderRadius || "4px"}
      width={(fullWidth && "100%") || width || "150px"}
      widthRandomness={randomWidth ? 0.25 : 0}
      {...rest}
    />
  </span>
);

export default Skeleton;
