import { useAtom } from "jotai";
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { ButtonSelect, Select } from "@ag/design-system/molecules";
import { grid, stack } from "@ag/design-system/utils";
import {
  ButtonSelectField,
  InputField,
  SelectField,
  setInputValueAsNumber,
} from "@ag/form-fields";
import I18n from "@ag/i18n";

import FormSection from "~components/form-section";

import { isDisabledAtom } from "../atoms/is-disabled-atom";
import { type FieldDefinitionData } from "../schemas/field-definition-form";
import { LimingType } from "../types/field-definition-data";
import AdditionalInformationTip from "./additional-information-tip";

const LimingFormSection = () => {
  const [isDisabled] = useAtom(isDisabledAtom);

  const { control, register, formState, setValue, watch } =
    useFormContext<FieldDefinitionData>();

  const limingType = watch("verraQuestionnaire.limingType");
  const limingApplicationRate = watch(
    "verraQuestionnaire.limingApplicationRate",
  );

  const errors = formState.errors.verraQuestionnaire;

  // Remove values if limingType is changed to none
  useEffect(() => {
    if (limingType === "none") {
      setValue("verraQuestionnaire.limingApplicationRate", undefined);
      setValue("verraQuestionnaire.limingFrequency", undefined);
    }
  }, [limingApplicationRate, limingType, setValue]);

  return (
    <FormSection.Root
      title={I18n.t(
        "js.carbon.field_details.field_definition.questionnaire.liming.title",
      )}
    >
      <FormSection.Card>
        <Controller
          name="verraQuestionnaire.limingType"
          control={control}
          render={({ field }) => (
            <ButtonSelectField
              {...field}
              label={I18n.t(
                "js.carbon.field_details.field_definition.questionnaire.liming.application",
              )}
              tooltip={I18n.t(
                "js.carbon.field_details.field_definition.questionnaire.liming.application_tip",
              )}
              testId="liming-type"
              error={errors?.limingType}
              isRequired
              isDisabled={isDisabled}
            >
              <ButtonSelect.Option value={LimingType.LIME}>
                {I18n.t(
                  "js.carbon.field_details.field_definition.questionnaire.apply_lime_dolomite.lime",
                )}
              </ButtonSelect.Option>

              <ButtonSelect.Option value={LimingType.DOLOMITE}>
                {I18n.t(
                  "js.carbon.field_details.field_definition.questionnaire.apply_lime_dolomite.dolomite",
                )}
              </ButtonSelect.Option>

              <ButtonSelect.Option value={LimingType.NONE}>
                {I18n.t(
                  "js.carbon.field_details.field_definition.questionnaire.apply_lime_dolomite.none",
                )}
              </ButtonSelect.Option>
            </ButtonSelectField>
          )}
        />

        {["lime", "dolomite"].includes(limingType as string) ? (
          <div className={stack({ gap: 24 })}>
            <AdditionalInformationTip />

            <div className={grid({ gap: 16 })}>
              <Controller
                name="verraQuestionnaire.limingFrequency"
                control={control}
                render={({ field }) => (
                  <SelectField
                    {...field}
                    value={field.value?.toString() ?? null}
                    label={I18n.t(
                      "js.carbon.field_details.field_definition.questionnaire.liming.frequency",
                    )}
                    error={errors?.limingFrequency}
                    placeholder={I18n.t("js.shared.select")}
                    testId="liming-frequency"
                    isDisabled={isDisabled}
                    onChange={value => field.onChange(Number(value))}
                  >
                    {Array.from(Array(15).keys()).map(i => (
                      <Select.Option key={i} value={`${i + 1}`}>
                        {I18n.t(
                          "js.carbon.field_details.field_definition.questionnaire.frequency_of_application.options",
                          { count: i + 1 },
                        )}
                      </Select.Option>
                    ))}
                  </SelectField>
                )}
              />

              <InputField
                {...register("verraQuestionnaire.limingApplicationRate", {
                  setValueAs: setInputValueAsNumber,
                })}
                type="number"
                step=".01"
                label={I18n.t(
                  "js.carbon.field_details.field_definition.questionnaire.liming.application_rate",
                )}
                rightAddon="t/ha"
                placeholder={I18n.t("js.shared.enter")}
                error={errors?.limingApplicationRate}
                data-testid="liming-application-rate"
                isDisabled={isDisabled}
                onWheel={e => e.currentTarget.blur()}
              />
            </div>
          </div>
        ) : undefined}
      </FormSection.Card>
    </FormSection.Root>
  );
};

export default LimingFormSection;
