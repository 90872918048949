export enum RoundingMode {
  Auto = "round",
  Up = "ceil",
  Down = "floor",
}

/**
 * Helper for rounding given number for nearest multiple based on the rounding mode
 *
 * Different modes examples:
 *
 * Inputs:
 * number = 1.5
 * multiple = 1
 *
 * RoundingMode.Auto  will return 2
 * RoundingMode.Up    will return 2
 * RoundingMode.Floor will return 1
 */
export const roundToNearestMultiple = (
  number: number,
  multiple: number,
  mode: RoundingMode = RoundingMode.Auto,
) => {
  const result =
    Math[mode](parseFloat((number / multiple).toFixed(1))) * multiple;

  return parseFloat(result.toFixed(2));
};

/**
 * Helper for rounding a float number to a given precision
 * @param value - number to be rounded
 * @param precision - number of decimal places to round to
 * @param mode - rounding mode
 * @returns rounded number
 */
export const decimalRound = (
  value: number,
  precision: number,
  mode: RoundingMode = RoundingMode.Auto,
) => {
  const multiplier = Math.pow(10, precision);

  return Math[mode](value * multiplier) / multiplier;
};
