import axios from "axios";
import { UseQueryOptions, useInfiniteQuery, useQuery } from "react-query";

import {
  CommonPaginatedResponse,
  Meta,
  MetaArgs,
  createPaginatedDataSchema,
} from "@ag/utils/types";

import { Farm, FarmSchema } from "../entities/farm";

export const farmsKeys = {
  all: ["farms"] as const,

  list: () => [...farmsKeys.all, "list"] as const,
  infiniteList: (params?: MetaArgs) =>
    [...farmsKeys.all, "infinite", params] as const,
};

export type UseFarmsQueryOptions<T> = Pick<UseQueryOptions<T>, "enabled"> & {
  onSuccess?: (data: { items: Farm[]; meta: Meta }) => void;
};

/**
 * FARMS
 */
export const fetchFarms = async (params: MetaArgs) => {
  const response = await axios.get<CommonPaginatedResponse<Farm>>(
    "/api/v1/farms",
    {
      params,
      apiSource: "node-carbon",
      schema: createPaginatedDataSchema(FarmSchema),
    },
  );

  return response.data.success!.data;
};

export const useFarmsQuery = <T>(
  params: MetaArgs,
  opts?: UseFarmsQueryOptions<T>,
) => useQuery(farmsKeys.list(), () => fetchFarms(params), opts);

export const useFarmsInfiniteQuery = (params: MetaArgs) =>
  useInfiniteQuery(
    farmsKeys.infiniteList(params),
    ({ pageParam = 1 }) => {
      return fetchFarms({ ...params, page: pageParam });
    },
    {
      getNextPageParam: prev => {
        return (prev?.meta.currentPage || 0) + 1;
      },
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );
