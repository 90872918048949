import axios from "axios";
import { UseQueryOptions, useQuery } from "react-query";

import { CommonPaginatedResponse } from "@ag/utils/types";

import { ValidationRequest } from "./types";

export const validationRequestsKeys = {
  all: ["validation-requests"] as const,

  list: () => [...validationRequestsKeys.all, "list"] as const,
};

/**
 * QUERY FUNCTIONS
 */
const fetchValidationRequests = async () => {
  const response = await axios.get<CommonPaginatedResponse<ValidationRequest>>(
    "/api/v1/carbon-validation-requests",
    {
      apiSource: "node-carbon",
      params: {
        limit: 100, // There is no pagination in the UI and other endpoint to fetch all the data so this
      },
    },
  );

  return response.data.success!.data.items;
};

/**
 * QUERY HOOKS
 */
export const useValidationRequestsQuery = (
  options?: Pick<UseQueryOptions, "enabled">,
) => useQuery(validationRequestsKeys.list(), fetchValidationRequests, options);
